import { Observable, throwError } from "rxjs";
import { catchError, retry } from "rxjs/operators";
import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpParams,
} from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { User } from "../../models/system/user";
import { Role } from "../../models/system/role";

@Injectable({
  providedIn: "root",
})
export class UsersService {
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };

  constructor(private http: HttpClient) {}

  getUsers(): Observable<User[]> {
    return this.http.get<User[]>(environment.root + "/mess/user");
  }

  getUser(id: string): Observable<User> {
    return this.http.get<User>(environment.root + "/mess/user/" + id);
  }

  getUserByMember(member: number): Observable<User> {
    return this.http.get<User>(
      environment.root + "/mess/user/getuserbymember/" + member
    );
  }

  addUser(user: User) {
    return this.http
      .post(environment.root + "/mess/user/add", user, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  modifyUser(user: User) {
    return this.http
      .post(environment.root + "/mess/user/modify", user, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  deleteUser(id: string) {
    return this.http
      .post(environment.root + "/mess/user/delete/" + id, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  login(formData) {
    return this.http.post(environment.root + "/mess/login/login", formData);
  }

  resetPassword(formData) {
    return this.http.post(
      environment.root + "mess/userlogin/resetpassword",
      formData
    );
  }

  forgotPassword(formData) {
    return this.http.post(
      environment.root + "/mess/userlogin/forgotpassword",
      formData
    );
  }

  changePassword(formData) {
    return this.http.post(
      environment.root + "/mess/user/changepassword",
      formData
    );
  }

  resetUserPassword(formData) {
    return this.http.post(
      environment.root + "/mess/user/resetuserpassword",
      formData
    );
  }

  getUserBySearch(page, pageSize, searchBy, searchText) {
    const params = new HttpParams()
      .set("page", page.toString())
      .set("pageSize", pageSize.toString())
      .set("searchBy", searchBy.toString())
      .set("searchText", searchText.toString());

    return this.http.get<{
      totalItems: number;
      listItemFrom: number;
      listItemTo: number;
      users: User[];
    }>(environment.root + "/mess/user/getalluserbysearch", { params });
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error("An error occurred:", error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    return throwError("Something bad happened; please try again later.");
  }
}
