import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormControl, FormGroup } from "@angular/forms";
import { Observable } from "rxjs";
import { NgbDateStruct, NgbCalendar } from "@ng-bootstrap/ng-bootstrap";
import { NgbStringAdapterService } from "src/app/shared/services/ngb-string-adapter.service";
import {
  HttpResponse,
  HttpResult,
} from "src/app/core/models/utils/http-response";
import Swal, { SweetAlertType } from "sweetalert2";

import { UserDetails } from "src/app/core/models/system/user-details";

import { Purchase } from "src/app/core/models/transactions/purchase";
import { PurchaseService } from "src/app/core/services/transactions/purchase.service";
import { Supplier } from "src/app/core/models/masters/supplier";
import { SupplierService } from "src/app/core/services/masters/supplier.service";
import { Expense } from "src/app/core/models/masters/expense";
import { ExpenseService } from "src/app/core/services/masters/expense.service";

@Component({
  selector: "app-purchase-page",
  templateUrl: "./purchase-page.component.html",
  styleUrls: ["./purchase-page.component.css"],
})
export class PurchasePageComponent implements OnInit {
  suppliers$: Observable<Supplier[]>;
  expenses$: Observable<Expense[]>;
  purchaseDate: NgbDateStruct;

  userDetails: UserDetails;
  menuId: number;

  id: number;

  purchaseForm = new FormGroup({
    id: new FormControl(0),
    purchaseNum: new FormControl(""),
    purchaseDate: new FormControl(new Date()),
    supplier: new FormControl(0),
    supplierName: new FormControl(""),
    expense: new FormControl(0),
    expenseName: new FormControl(""),
    amount: new FormControl(0),
    notes: new FormControl(""),
    status: new FormControl(false),
  });

  constructor(
    private purchaseService: PurchaseService,
    private supplierService: SupplierService,
    private expenseService: ExpenseService,
    private ngbDateAdapter: NgbStringAdapterService,
    private calendar: NgbCalendar,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.purchaseDate = this.calendar.getToday();
    this.userDetails = JSON.parse(localStorage.getItem("userDetails"));
    this.menuId = 28000;

    this.id = 0;
    this.suppliers$ = this.supplierService.getSuppliers();
    this.expenses$ = this.expenseService.getExpenses();

    this.route.paramMap.subscribe((params) => {
      const expID = +params.get("id");
      if (expID) {
        this.getPurchase(expID);
      }
    });
    this.purchaseDate = this.calendar.getToday();
  }

  cancelPurchase() {
    this.router.navigate(["./", { outlets: { pages: ["purchase"] } }], {
      relativeTo: this.route.parent,
    });
  }

  getPurchase(id: number) {
    this.purchaseService.getPurchase(id).subscribe(
      (purchase: Purchase) => this.editPurchase(purchase),
      (err: any) => {
        this.displayFeedback(
          "error",
          "Error!",
          "Something went wrong. Try again!"
        );
        this.router.navigate(["./", { outlets: { pages: ["purchase"] } }], {
          relativeTo: this.route.parent,
        });
      }
    );
  }

  editPurchase(purchase: Purchase) {
    this.purchaseForm.patchValue({
      id: purchase.id,
      purchaseNum: purchase.purchaseNum,
      purchaseDate: purchase.purchaseDate,
      supplier: purchase.supplier,
      supplierName: purchase.supplierName,
      expense: purchase.expense,
      expenseName: purchase.expenseName,
      amount: purchase.amount,
      notes: purchase.notes,
      status: purchase.status,
    });
    this.id = purchase.id;

    this.purchaseDate = this.ngbDateAdapter.fromModel(
      new Date(purchase.purchaseDate)
    );
  }

  savePurchase(reset: boolean) {
    const purchase: Purchase = {
      id: this.purchaseForm.value.id,
      purchaseNum: this.purchaseForm.value.purchaseNum,
      purchaseDate: this.purchaseForm.value.purchaseDate,
      supplier: this.purchaseForm.value.supplier,
      supplierName: this.purchaseForm.value.supplierName,
      expense: this.purchaseForm.value.expense,
      expenseName: this.purchaseForm.value.expenseName,
      amount: this.purchaseForm.value.amount,
      notes: this.purchaseForm.value.notes,
      status: this.purchaseForm.value.status,
    };

    if (purchase.id < 1) {
      this.purchaseService.add(purchase).subscribe(
        (response: HttpResponse) => {
          if (response.result === HttpResult.Success) {
            this.displayFeedback("success", "Saved Successfully!", "");
            if (reset) {
              this.resetPurchase();
            } else {
              this.router.navigate(
                ["./", { outlets: { pages: ["purchase"] } }],
                { relativeTo: this.route.parent }
              );
            }
          } else {
            this.displayFeedback(
              "warning",
              response.result + "!",
              response.message
            );
          }
        },
        (error) => {
          this.displayFeedback("error", "Error!", error);
        }
      );
    } else {
      this.purchaseService.modify(purchase).subscribe(
        (response: HttpResponse) => {
          if (response.result === HttpResult.Success) {
            this.displayFeedback("success", "Saved Successfully!", "");
            if (reset) {
              this.resetPurchase();
            } else {
              this.router.navigate(
                ["./", { outlets: { pages: ["purchase"] } }],
                { relativeTo: this.route.parent }
              );
            }
          } else {
            this.displayFeedback(
              "warning",
              response.result + "!",
              response.message
            );
          }
        },
        (error) => {
          this.displayFeedback("error", "Error!", error);
        }
      );
    }
  }

  resetPurchase() {
    this.purchaseForm.reset({
      id: 0,
      purchaseNum: "",
      purchaseDate: new Date(),
      supplier: 0,
      supplierName: "",
      expense: 0,
      expenseName: "",
      amount: 0,
      notes: "",
      status: false,
    });
  }

  hasAdd() {
    let privilage = false;

    this.userDetails.menus.forEach((value) => {
      if (value.id === this.menuId) {
        if (value.privilegeAdd && this.purchaseForm.value.id < 1)
          privilage = true;
        else if (value.privilegeModify && this.purchaseForm.value.id > 0)
          privilage = true;
      }
    });

    return privilage;
  }

  displayFeedback(stype: SweetAlertType, stitle: string, stext: string) {
    Swal.fire({
      toast: true,
      type: stype,
      title: stitle,
      text: stext,
      showConfirmButton: false,
      position: "top",
      timer: 3000,
    });
  }

  displayHTMLFeedback(stype: SweetAlertType, stitle: string, shtml: string) {
    Swal.fire({
      type: stype,
      title: stitle,
      html: shtml,
      showConfirmButton: true,
    });
  }
}
