import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormControl, FormGroup } from "@angular/forms";
import { Observable, concat, of, Subject } from "rxjs";
import {
  debounceTime,
  map,
  distinctUntilChanged,
  tap,
  switchMap,
  catchError,
} from "rxjs/operators";
import { NgbDateStruct, NgbCalendar } from "@ng-bootstrap/ng-bootstrap";
import { NgbStringAdapterService } from "src/app/shared/services/ngb-string-adapter.service";
import {
  HttpResponse,
  HttpResult,
} from "src/app/core/models/utils/http-response";
import Swal, { SweetAlertType } from "sweetalert2";

import { UserDetails } from "src/app/core/models/system/user-details";

import { JoinMess } from "src/app/core/models/transactions/join-mess";
import { JoinMessService } from "src/app/core/services/transactions/join-mess.service";
import { Member } from "src/app/core/models/masters/member";
import { MemberList } from "src/app/core/models/masters/member-list";
import { MemberService } from "src/app/core/services/masters/member.service";
import { Setting } from "src/app/core/models/system/setting";
import { SettingService } from "src/app/core/services/system/setting.service";

@Component({
  selector: "app-join-mess-page",
  templateUrl: "./join-mess-page.component.html",
  styleUrls: ["./join-mess-page.component.css"],
})
export class JoinMessPageComponent implements OnInit {
  members$: Member[];
  joinDate: NgbDateStruct;

  userDetails: UserDetails;
  menuId: number;

  id: number;
  member: number;
  memberName: string;

  joinMessForm = new FormGroup({
    id: new FormControl(0),
    member: new FormControl({ id: 0, name: "" }),
    memberName: new FormControl(""),
    joinDate: new FormControl(new Date()),
    quantity: new FormControl(2),
    hasRegistrationAndAdvance: new FormControl(false),
    registrationFees: new FormControl(0),
    advanceAmount: new FormControl(0),
    hasParcel: new FormControl(false),
    notes: new FormControl(""),
  });

  memberLoading = false;
  member$: Observable<MemberList[]>;
  memberInput$ = new Subject<string>();
  selectedMember: any;

  constructor(
    private joinMessService: JoinMessService,
    private memberService: MemberService,
    private settingService: SettingService,
    private ngbDateAdapter: NgbStringAdapterService,
    private calendar: NgbCalendar,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.joinDate = this.calendar.getToday();

    this.loadMembers();
    this.userDetails = JSON.parse(localStorage.getItem("userDetails"));
    this.menuId = 21000;

    this.id = 0;

    this.route.paramMap.subscribe((params) => {
      const expID = +params.get("id");
      if (expID) {
        this.getJoinMess(expID);
      }
    });
  }

  cancelJoinMess() {
    this.router.navigate(["./", { outlets: { pages: ["join-mess"] } }], {
      relativeTo: this.route.parent,
    });
  }

  getJoinMess(id: number) {
    this.joinMessService.getJoinMess(id).subscribe(
      (joinMess: JoinMess) => this.editJoinMess(joinMess),
      (err: any) => {
        this.displayFeedback(
          "error",
          "Error!",
          "Something went wrong. Try again!"
        );
        this.router.navigate(["./", { outlets: { pages: ["join-mess"] } }], {
          relativeTo: this.route.parent,
        });
      }
    );
  }

  editJoinMess(joinMess: JoinMess) {
    this.joinMessForm.patchValue({
      id: joinMess.id,
      member: { id: joinMess.member, name: joinMess.memberName },
      memberName: joinMess.memberName,
      joinDate: joinMess.joinDate,
      quantity: joinMess.quantity,
      hasRegistrationAndAdvance: joinMess.hasRegistrationAndAdvance,
      registrationFees: joinMess.registrationFees,
      advanceAmount: joinMess.advanceAmount,
      hasParcel: joinMess.hasParcel,
      notes: joinMess.notes,
    });
    this.id = joinMess.id;
    this.member = joinMess.member;
    this.memberName = joinMess.memberName;

    this.joinDate = this.ngbDateAdapter.fromModel(new Date(joinMess.joinDate));
  }

  saveJoinMess(reset: boolean) {
    const joinMess: JoinMess = {
      id: this.joinMessForm.value.id,
      joinDate: this.joinMessForm.value.joinDate,
      member: this.joinMessForm.value.member.id,
      memberName: this.joinMessForm.value.member.name,
      quantity: this.joinMessForm.value.quantity,
      hasRegistrationAndAdvance:
        this.joinMessForm.value.hasRegistrationAndAdvance,
      registrationFees: this.joinMessForm.value.registrationFees,
      advanceAmount: this.joinMessForm.value.advanceAmount,
      hasParcel: this.joinMessForm.value.hasParcel,
      notes: this.joinMessForm.value.notes,
    };
    console.log(joinMess);
    if (joinMess.id < 1) {
      this.joinMessService.add(joinMess).subscribe(
        (response: HttpResponse) => {
          if (response.result === HttpResult.Success) {
            this.displayFeedback("success", "Saved Successfully!", "");
            if (reset) {
              this.resetJoinMess();
            } else {
              this.router.navigate(
                ["./", { outlets: { pages: ["join-mess"] } }],
                { relativeTo: this.route.parent }
              );
            }
          } else {
            this.displayFeedback(
              "warning",
              response.result + "!",
              response.message
            );
          }
        },
        (error) => {
          this.displayFeedback("error", "Error!", error);
        }
      );
    } else {
      this.joinMessService.modify(joinMess).subscribe(
        (response: HttpResponse) => {
          if (response.result === HttpResult.Success) {
            this.displayFeedback("success", "Saved Successfully!", "");
            if (reset) {
              this.resetJoinMess();
            } else {
              this.router.navigate(
                ["./", { outlets: { pages: ["join-mess"] } }],
                { relativeTo: this.route.parent }
              );
            }
          } else {
            this.displayFeedback(
              "warning",
              response.result + "!",
              response.message
            );
          }
        },
        (error) => {
          this.displayFeedback("error", "Error!", error);
        }
      );
    }
  }

  getMemberDetails() {
    if (this.joinMessForm.value.member != null) {
      this.memberService
        .getMemberAdvanceInfo(
          this.joinMessForm.value.member.id,
          this.joinDate.year +
            "-" +
            this.joinDate.month +
            "-" +
            this.joinDate.day
          // this.joinMessForm.value.joinDate
        )
        .subscribe(
          (response) => {
            if (response.advanceAmount === 0) {
              if (
                confirm(
                  "Advance amount of member is zero. Do you want to add Advance?"
                )
              ) {
                this.getRegistrationFeesAndAdvance();
              }
            }
          },
          (err: any) => console.log(err)
        );
    }
  }

  getRegistrationFeesAndAdvance() {
    this.settingService.getSetting().subscribe(
      (setting: Setting) => this.setRegFeesAndAdvance(setting),
      (err: any) => console.log(err)
    );
  }
  setRegFeesAndAdvance(setting: Setting) {
    this.joinMessForm.patchValue({
      hasRegistrationAndAdvance: true,
      registrationFees: setting.registrationFees,
      advanceAmount: setting.advanceAmount,
    });
  }

  resetJoinMess() {
    this.joinMessForm.reset({
      id: 0,
      joinDate: new Date(),
      member: { id: 0, name: "" },
      memberName: "",
      quantity: 2,
      hasRegistrationAndAdvance: false,
      registrationFees: 0,
      advanceAmount: 0,
      hasParcel: false,
      notes: "",
    });
  }

  private loadMembers() {
    this.member$ = concat(
      of([]),
      this.memberInput$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => (this.memberLoading = true)),
        switchMap((term) =>
          this.memberService.getMembersListNotInMess(term).pipe(
            catchError(() => of([])),
            tap(() => (this.memberLoading = false))
          )
        )
      )
    );
  }

  hasAdd() {
    let privilage = false;

    this.userDetails.menus.forEach((value) => {
      if (value.id === this.menuId) {
        if (value.privilegeAdd && this.joinMessForm.value.id < 1)
          privilage = true;
        else if (value.privilegeModify && this.joinMessForm.value.id > 0)
          privilage = true;
      }
    });

    return privilage;
  }

  displayFeedback(stype: SweetAlertType, stitle: string, stext: string) {
    Swal.fire({
      toast: true,
      type: stype,
      title: stitle,
      text: stext,
      showConfirmButton: false,
      position: "top",
      timer: 3000,
    });
  }

  displayHTMLFeedback(stype: SweetAlertType, stitle: string, shtml: string) {
    Swal.fire({
      type: stype,
      title: stitle,
      html: shtml,
      showConfirmButton: true,
    });
  }
}
