import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { ActiveMembers } from "../../models/reports/active-members";
import { AdvanceRegister } from "../../models/reports/advance-register";
import { MemberStatements } from "../../models/reports/member-statement";
import { MemberOutstandings } from "../../models/reports/member-outstandings";
import { ReceiptSummarys } from "../../models/reports/receipt-summary";
import { InvoiceRegister } from "../../models/reports/invoice-register";
import { PurchaseSummarys } from "../../models/reports/purchase-summary";
import { RecurringExpenseSummarys } from "../../models/reports/recurring-expense-summary";
import { CollectionRegister } from "../../models/reports/collection-register";
import { PaymentSummarys } from "../../models/reports/payment-summary";
import { AdvanceReturnSummarys } from "../../models/reports/advance-return-summary";

@Injectable({
  providedIn: "root",
})
export class ReportGeneratorService {
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };

  constructor(private http: HttpClient) {}

  getDashboard(): Observable<any> {
    return this.http.get<any>(
      environment.root + "/mess/reportsgenerator/getdashboard"
    );
  }

  getActiveMembers(searchFields): Observable<ActiveMembers[]> {
    return this.http.post<ActiveMembers[]>(
      environment.root + "/mess/reportsgenerator/getactivemembers",
      searchFields
    );
  }

  getAdvanceRegister(searchFields): Observable<AdvanceRegister> {
    return this.http.post<AdvanceRegister>(
      environment.root + "/mess/reportsgenerator/getadvanceregister",
      searchFields
    );
  }

  getMemberStatements(searchFields): Observable<MemberStatements> {
    return this.http.post<MemberStatements>(
      environment.root + "/mess/reportsgenerator/getmemberstatements",
      searchFields
    );
  }

  getMemberOutstandings(searchFields): Observable<MemberOutstandings> {
    return this.http.post<MemberOutstandings>(
      environment.root + "/mess/reportsgenerator/getmemberoutstandings",
      searchFields
    );
  }

  getReceiptSummary(searchFields): Observable<ReceiptSummarys> {
    return this.http.post<ReceiptSummarys>(
      environment.root + "/mess/reportsgenerator/getreceiptsummary",
      searchFields
    );
  }

  getInvoiceRegister(searchFields): Observable<InvoiceRegister> {
    return this.http.post<InvoiceRegister>(
      environment.root + "/mess/reportsgenerator/getmessbill",
      searchFields
    );
  }

  getCollectionRegister(searchFields): Observable<CollectionRegister> {
    return this.http.post<CollectionRegister>(
      environment.root + "/mess/reportsgenerator/getmessbillcollection",
      searchFields
    );
  }

  getPurchaseSummary(searchFields): Observable<PurchaseSummarys> {
    return this.http.post<PurchaseSummarys>(
      environment.root + "/mess/reportsgenerator/getpurchasesummary",
      searchFields
    );
  }

  getRecurringExpenseSummary(
    searchFields
  ): Observable<RecurringExpenseSummarys> {
    return this.http.post<RecurringExpenseSummarys>(
      environment.root + "/mess/reportsgenerator/getrecurringexpensesummary",
      searchFields
    );
  }

  getPaymentSummary(searchFields): Observable<PaymentSummarys> {
    return this.http.post<PaymentSummarys>(
      environment.root + "/mess/reportsgenerator/getpaymentsummary",
      searchFields
    );
  }

  getAdvanceReturnSummary(searchFields): Observable<AdvanceReturnSummarys> {
    return this.http.post<AdvanceReturnSummarys>(
      environment.root + "/mess/reportsgenerator/getadvancereturntsummary",
      searchFields
    );
  }
}
