import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { SweetAlert2Module } from "@sweetalert2/ngx-sweetalert2";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerModule } from "ngx-spinner";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { JoinMessPageComponent } from "./join-mess-page/join-mess-page.component";
import { JoinMessListPageComponent } from "./join-mess-list-page/join-mess-list-page.component";
import { LeaveMessPageComponent } from "./leave-mess-page/leave-mess-page.component";
import { LeaveMessListPageComponent } from "./leave-mess-list-page/leave-mess-list-page.component";
import { AdvanceReturnListPageComponent } from "./advance-return-list-page/advance-return-list-page.component";
import { AdvanceReturnPageComponent } from "./advance-return-page/advance-return-page.component";
import { AdjustmentPageComponent } from "./adjustment-page/adjustment-page.component";
import { AdjustmentListPageComponent } from "./adjustment-list-page/adjustment-list-page.component";
import { ReceiptListPageComponent } from "./receipt-list-page/receipt-list-page.component";
import { ReceiptPageComponent } from "./receipt-page/receipt-page.component";
import { PaymentPageComponent } from "./payment-page/payment-page.component";
import { PaymentListPageComponent } from "./payment-list-page/payment-list-page.component";
import { PurchasePageComponent } from "./purchase-page/purchase-page.component";
import { PurchaseListPageComponent } from "./purchase-list-page/purchase-list-page.component";
import { RecurringExpenseListPageComponent } from "./recurring-expense-list-page/recurring-expense-list-page.component";
import { RecurringExpensePageComponent } from "./recurring-expense-page/recurring-expense-page.component";
import { GenerateInvoicePageComponent } from "./generate-invoice-page/generate-invoice-page.component";
import { GenerateInvoiceListPageComponent } from "./generate-invoice-list-page/generate-invoice-list-page.component";
import { InvoiceListPageComponent } from "./invoice-list-page/invoice-list-page.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { CoreModule } from "../core/core.module";

@NgModule({
  declarations: [
    JoinMessPageComponent,
    JoinMessListPageComponent,
    LeaveMessPageComponent,
    LeaveMessListPageComponent,
    AdvanceReturnListPageComponent,
    AdvanceReturnPageComponent,
    AdjustmentPageComponent,
    AdjustmentListPageComponent,
    ReceiptListPageComponent,
    ReceiptPageComponent,
    PaymentPageComponent,
    PaymentListPageComponent,
    PurchasePageComponent,
    PurchaseListPageComponent,
    RecurringExpenseListPageComponent,
    RecurringExpensePageComponent,
    GenerateInvoicePageComponent,
    GenerateInvoiceListPageComponent,
    InvoiceListPageComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SweetAlert2Module,
    NgbModule.forRoot(),
    NgxSpinnerModule,
    BrowserAnimationsModule,
    NgSelectModule,
    CoreModule,
  ],
  exports: [],
})
export class TransactionsModule {}
