import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormControl, FormGroup } from "@angular/forms";
import { Observable, concat, of, Subject } from "rxjs";
import {
  debounceTime,
  map,
  distinctUntilChanged,
  tap,
  switchMap,
  catchError,
} from "rxjs/operators";
import { NgbDateStruct, NgbCalendar } from "@ng-bootstrap/ng-bootstrap";
import { NgbStringAdapterService } from "src/app/shared/services/ngb-string-adapter.service";
import {
  HttpResponse,
  HttpResult,
} from "src/app/core/models/utils/http-response";
import Swal, { SweetAlertType } from "sweetalert2";

import { UserDetails } from "src/app/core/models/system/user-details";

import { MonthlyProcess } from "src/app/core/models/transactions/monthly-process";
import { MonthlyProcessService } from "src/app/core/services/transactions/monthly-process.service";

@Component({
  selector: "app-generate-invoice-page",
  templateUrl: "./generate-invoice-page.component.html",
  styleUrls: ["./generate-invoice-page.component.css"],
})
export class GenerateInvoicePageComponent implements OnInit {
  fromDate: NgbDateStruct;
  toDate: NgbDateStruct;

  userDetails: UserDetails;
  menuId: number;

  id: number;

  monthlyProcessForm = new FormGroup({
    id: new FormControl(0),
    name: new FormControl(""),
    fromDate: new FormControl(new Date()),
    toDate: new FormControl(new Date()),
    openingBalance: new FormControl(0),
    totalCollection: new FormControl(0),
    totalPayments: new FormControl(0),
    totalPurchaseExpense: new FormControl(0),
    totalRecurringExpense: new FormControl(0),
    closingBalance: new FormControl(0),
    notes: new FormControl(""),
    status: new FormControl("N"),
  });

  constructor(
    private monthlyProcessService: MonthlyProcessService,
    private ngbDateAdapter: NgbStringAdapterService,
    private calendar: NgbCalendar,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.fromDate = this.calendar.getToday();
    this.toDate = this.calendar.getToday();
    this.userDetails = JSON.parse(localStorage.getItem("userDetails"));
    this.menuId = 29100;

    this.id = 0;

    this.route.paramMap.subscribe((params) => {
      const expID = +params.get("id");
      if (expID) {
        this.getMonthlyProcess(expID);
      }
    });
  }

  cancelMonthlyProcess() {
    this.router.navigate(["./", { outlets: { pages: ["generate-invoice"] } }], {
      relativeTo: this.route.parent,
    });
  }

  getMonthlyProcess(id: number) {
    this.monthlyProcessService.getMonthlyProcess(id).subscribe(
      (monthlyProcess: MonthlyProcess) =>
        this.editMonthlyProcess(monthlyProcess),
      (err: any) => {
        this.displayFeedback(
          "error",
          "Error!",
          "Something went wrong. Try again!"
        );
        this.router.navigate(
          ["./", { outlets: { pages: ["generate-invoice"] } }],
          {
            relativeTo: this.route.parent,
          }
        );
      }
    );
  }

  editMonthlyProcess(monthlyProcess: MonthlyProcess) {
    this.monthlyProcessForm.patchValue({
      id: monthlyProcess.id,
      name: monthlyProcess.name,
      fromDate: monthlyProcess.fromDate,
      toDate: monthlyProcess.toDate,
      openingBalance: monthlyProcess.openingBalance,
      totalCollection: monthlyProcess.totalCollection,
      totalPayments: monthlyProcess.totalPayments,
      totalPurchaseExpense: monthlyProcess.totalPurchaseExpense,
      totalRecurringExpense: monthlyProcess.totalRecurringExpense,
      closingBalance: monthlyProcess.closingBalance,
      notes: monthlyProcess.notes,
      status: monthlyProcess.status,
    });
    this.id = monthlyProcess.id;

    this.fromDate = this.ngbDateAdapter.fromModel(
      new Date(monthlyProcess.fromDate)
    );
    this.toDate = this.ngbDateAdapter.fromModel(
      new Date(monthlyProcess.toDate)
    );
  }

  saveMonthlyProcess(reset: boolean) {
    const monthlyProcess: MonthlyProcess = {
      id: this.monthlyProcessForm.value.id,
      name: this.monthlyProcessForm.value.name,
      fromDate: this.monthlyProcessForm.value.fromDate,
      toDate: this.monthlyProcessForm.value.toDate,
      openingBalance: this.monthlyProcessForm.value.openingBalance,
      totalCollection: this.monthlyProcessForm.value.totalCollection,
      totalPayments: this.monthlyProcessForm.value.totalPayments,
      totalPurchaseExpense: this.monthlyProcessForm.value.totalPurchaseExpense,
      totalRecurringExpense:
        this.monthlyProcessForm.value.totalRecurringExpense,
      closingBalance: this.monthlyProcessForm.value.closingBalance,
      notes: this.monthlyProcessForm.value.notes,
      status: this.monthlyProcessForm.value.status,
    };

    if (monthlyProcess.id < 1) {
      this.monthlyProcessService.add(monthlyProcess).subscribe(
        (response: HttpResponse) => {
          if (response.result === HttpResult.Success) {
            this.displayFeedback("success", "Saved Successfully!", "");
            if (reset) {
              this.resetMonthlyProcess();
            } else {
              this.router.navigate(
                ["./", { outlets: { pages: ["generate-invoice"] } }],
                { relativeTo: this.route.parent }
              );
            }
          } else {
            this.displayFeedback(
              "warning",
              response.result + "!",
              response.message
            );
          }
        },
        (error) => {
          this.displayFeedback("error", "Error!", error);
        }
      );
    } else {
      this.monthlyProcessService.modify(monthlyProcess).subscribe(
        (response: HttpResponse) => {
          if (response.result === HttpResult.Success) {
            this.displayFeedback("success", "Saved Successfully!", "");
            if (reset) {
              this.resetMonthlyProcess();
            } else {
              this.router.navigate(
                ["./", { outlets: { pages: ["generate-invoice"] } }],
                { relativeTo: this.route.parent }
              );
            }
          } else {
            this.displayFeedback(
              "warning",
              response.result + "!",
              response.message
            );
          }
        },
        (error) => {
          this.displayFeedback("error", "Error!", error);
        }
      );
    }
  }

  resetMonthlyProcess() {
    this.monthlyProcessForm.reset({
      id: 0,
      name: "",
      fromDate: new Date(),
      toDate: new Date(),
      openingBalance: 0,
      totalCollection: 0,
      totalPayments: 0,
      totalPurchaseExpense: 0,
      totalRecurringExpense: 0,
      closingBalance: 0,
      notes: "",
      status: "N",
    });
  }

  hasAdd() {
    let privilage = false;

    this.userDetails.menus.forEach((value) => {
      if (value.id === this.menuId) {
        if (value.privilegeAdd && this.monthlyProcessForm.value.id < 1)
          privilage = true;
        else if (value.privilegeModify && this.monthlyProcessForm.value.id > 0)
          privilage = true;
      }
    });

    return privilage;
  }

  displayFeedback(stype: SweetAlertType, stitle: string, stext: string) {
    Swal.fire({
      toast: true,
      type: stype,
      title: stitle,
      text: stext,
      showConfirmButton: false,
      position: "top",
      timer: 3000,
    });
  }

  displayHTMLFeedback(stype: SweetAlertType, stitle: string, shtml: string) {
    Swal.fire({
      type: stype,
      title: stitle,
      html: shtml,
      showConfirmButton: true,
    });
  }
}
