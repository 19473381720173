export const environment = {
  production: false,
  root: "https://karama-mess.xyz/mess-api/api",
  fileSystem: "https://karama-mess.xyz/mess-api/filesystem",
  //root: "https://localhost:44370/api",
  //
  //for demo
  // root: "https://demo.fsuite.net/mms-api/api",
  // fileSystem: "https://demo.fsuite.net/mms-api/filesystem",
};
//http://localhost:54475/api
