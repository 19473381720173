import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import Swal, { SweetAlertType } from 'sweetalert2';

import { UsersService } from './../../services/system/users.service';
import { HttpResponse, HttpResult } from '../../models/utils/http-response';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  forgotPasswordForm = new FormGroup({
    UserId: new FormControl(''),
    Email: new FormControl('')
  });

  constructor(private userService: UsersService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {

  }

  onSubmit() {
    this.userService.forgotPassword(this.forgotPasswordForm.value).subscribe(
      (res: HttpResponse) => {
        if (res.result === HttpResult.Success) {
          this.displayHTMLFeedback('success', 'Password link send in registered email!', '');
          this.router.navigateByUrl('login');
        } else {
          this.displayHTMLFeedback('warning', res.result + '!', res.message);
        }
      },
      err => {
        if (err.status === 400) {
          this.displayFeedback('warning', 'Incorrect username or email', 'Authentication failed.!');
        } else {
          this.displayFeedback('error', 'Error!', err);
        }
      }
    );
  }

  resetLogin() {
    this.forgotPasswordForm.reset({
      Id: '',
      Email: ''
    });
  }

  displayFeedback(stype: SweetAlertType, stitle: string, stext: string) {
    Swal.fire({
      toast: true,
      type: stype,
      title: stitle,
      text: stext,
      showConfirmButton: false,
      position: 'top',
      timer: 3000
    });
  }

  displayHTMLFeedback(stype: SweetAlertType, stitle: string, shtml: string) {
    Swal.fire({
      type: stype,
      title: stitle,
      html: shtml,
      showConfirmButton: true
    });
  }
}
