import { Component, OnInit, ContentChild } from "@angular/core";
import { concat, Observable, of, Subject } from "rxjs";
import { NgbDateStruct, NgbCalendar } from "@ng-bootstrap/ng-bootstrap";
import Swal, { SweetAlertType } from "sweetalert2";
import * as XLSX from "xlsx";
import { ViewChild, ElementRef } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import {
  debounceTime,
  map,
  distinctUntilChanged,
  tap,
  switchMap,
  catchError,
} from "rxjs/operators";

import { UserDetails } from "src/app/core/models/system/user-details";
import { FormControl, FormGroup } from "@angular/forms";

import { MonthlyProcess } from "src/app/core/models/transactions/monthly-process";
import { MonthlyProcessService } from "src/app/core/services/transactions/monthly-process.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-monthly-statement",
  templateUrl: "./monthly-statement.component.html",
  styleUrls: ["./monthly-statement.component.css"],
})
export class MonthlyStatementComponent implements OnInit {
  @ViewChild("table", { static: true }) table: ElementRef;

  userDetails: UserDetails;
  menuId: number;
  id: number;
  listCount: any;
  processes$: Observable<MonthlyProcess[]>;
  monthlyProcess: MonthlyProcess;

  statementForm = new FormGroup({
    process: new FormControl(0),
  });

  constructor(
    private calendar: NgbCalendar,
    private monthlyProcessService: MonthlyProcessService,
    private spinnerService: NgxSpinnerService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.processes$ = this.monthlyProcessService.getMonthlyProcesss();
    this.menuId = 31000;
    this.listCount = 0;
    this.userDetails = JSON.parse(localStorage.getItem("userDetails"));
    this.processes$.subscribe((res) => console.log(res));
    this.route.paramMap.subscribe((params) => {
      const expID = +params.get("id");
      if (expID) {
        this.setMonthlyStatementLists(expID);
      }
    });
  }

  resetFilters() {
    this.statementForm.reset({
      process: 0,
    });
  }

  setMonthlyStatementLists(process: number) {
    this.statementForm.patchValue({
      process: process,
    }); 
      this.spinnerService.show();

      this.monthlyProcessService
        .getMonthlyProcess(this.statementForm.value.process)
        .subscribe(
          (response: any) => {
            this.monthlyProcess = response;
            this.spinnerService.hide();
          },
          (err: any) => {
            console.log(err);
            this.spinnerService.hide();
          }
        );
      if (this.listCount === null) {
        this.listCount = 0;
      }
  }

  setMonthlyStatementList() {
    if (this.statementForm.value.process === 0) {
      this.displayFeedback("warning", "Please select a month!", "");
    } else {
      this.spinnerService.show();

      this.monthlyProcessService
        .getMonthlyProcess(this.statementForm.value.process)
        .subscribe(
          (response: any) => {
            this.monthlyProcess = response;
            this.spinnerService.hide();
          },
          (err: any) => {
            console.log(err);
            this.spinnerService.hide();
          }
        );
      if (this.listCount === null) {
        this.listCount = 0;
      }
    }
  }

  hasView() {
    let privilage = false;
    this.userDetails.menus.forEach((value) => {
      if (value.id === this.menuId) {
        if (value.privilegeView || value.privilegeModify) privilage = true;
      }
    });

    return privilage;
  }

  hasExport() {
    let privilage = false;

    this.userDetails.menus.forEach((value) => {
      if (value.id === this.menuId) {
        if (value.privilegeExport) privilage = true;
      }
    });

    return privilage;
  }

  exportToExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      this.table.nativeElement
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "MonthlyStatement");

    XLSX.writeFile(wb, "MonthlyStatement.xlsx");
  }

  displayFeedback(stype: SweetAlertType, stitle: string, stext: string) {
    Swal.fire({
      toast: true,
      type: stype,
      title: stitle,
      text: stext,
      showConfirmButton: false,
      position: "top",
      timer: 3000,
    });
  }
}
