import { Observable, throwError } from "rxjs";
import { catchError, retry } from "rxjs/operators";
import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpParams,
} from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { HttpResponse } from "../../models/utils/http-response";
import { LeaveMess } from "../../models/transactions/leave-mess";

@Injectable({
  providedIn: "root",
})
export class LeaveMessService {
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };

  constructor(private http: HttpClient) {}

  getLeaveMesss(): Observable<LeaveMess[]> {
    return this.http.get<LeaveMess[]>(environment.root + "/mess/leavemess");
  }

  getLeaveMess(id: number): Observable<LeaveMess> {
    return this.http.get<LeaveMess>(environment.root + "/mess/leavemess/" + id);
  }

  add(leaveMess: LeaveMess): Observable<HttpResponse> {
    return this.http
      .post<HttpResponse>(
        environment.root + "/mess/leavemess/add",
        leaveMess,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  modify(leaveMess: LeaveMess): Observable<HttpResponse> {
    return this.http
      .post<HttpResponse>(
        environment.root + "/mess/leavemess/modify",
        leaveMess,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  delete(id: number): Observable<HttpResponse> {
    return this.http
      .post<HttpResponse>(
        environment.root + "/mess/leavemess/delete/" + id,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  getLeaveMessBySearch(page, pageSize, searchBy, searchText) {
    const params = new HttpParams()
      .set("page", page.toString())
      .set("pageSize", pageSize.toString())
      .set("searchBy", searchBy.toString())
      .set("searchText", searchText.toString());

    return this.http.get<{
      totalItems: number;
      listItemFrom: number;
      listItemTo: number;
      leaves: LeaveMess[];
    }>(environment.root + "/mess/leavemess/getallleavemessbysearch", {
      params,
    });
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error("An error occurred:", error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    return throwError("Something bad happened; please try again later.");
  }
}
