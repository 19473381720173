import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormControl, FormGroup } from "@angular/forms";
import { Observable, concat, of, Subject } from "rxjs";
import {
  debounceTime,
  map,
  distinctUntilChanged,
  tap,
  switchMap,
  catchError,
} from "rxjs/operators";
import { NgbDateStruct, NgbCalendar } from "@ng-bootstrap/ng-bootstrap";
import { NgbStringAdapterService } from "src/app/shared/services/ngb-string-adapter.service";
import {
  HttpResponse,
  HttpResult,
} from "src/app/core/models/utils/http-response";
import Swal, { SweetAlertType } from "sweetalert2";

import { UserDetails } from "src/app/core/models/system/user-details";

import { Adjustment } from "src/app/core/models/transactions/adjustment";
import { AdjustmentService } from "src/app/core/services/transactions/adjustment.service";
import { Member } from "src/app/core/models/masters/member";
import { MemberList } from "src/app/core/models/masters/member-list";
import { MemberService } from "src/app/core/services/masters/member.service";
import { JoinMessService } from "src/app/core/services/transactions/join-mess.service";

@Component({
  selector: "app-adjustment-page",
  templateUrl: "./adjustment-page.component.html",
  styleUrls: ["./adjustment-page.component.css"],
})
export class AdjustmentPageComponent implements OnInit {
  members$: Member[];
  adjustmentDate: NgbDateStruct;

  userDetails: UserDetails;
  menuId: number;

  id: number;
  member: number;
  memberName: string;

  adjustmentForm = new FormGroup({
    id: new FormControl(0),
    adjustmentNum: new FormControl(""),
    adjustmentDate: new FormControl(new Date()),
    member: new FormControl({ id: 0, name: "" }),
    memberName: new FormControl(""),
    prevBalAmt: new FormControl(0),
    amount: new FormControl(0),
    currentBalAmt: new FormControl(0),
    totalBalAmt: new FormControl(0),
    notes: new FormControl(""),
    status: new FormControl(false),
  });

  memberLoading = false;
  member$: Observable<MemberList[]>;
  memberInput$ = new Subject<string>();
  selectedMember: any;

  constructor(
    private adjustmentService: AdjustmentService,
    private joinMessService: JoinMessService,
    private memberService: MemberService,
    private ngbDateAdapter: NgbStringAdapterService,
    private calendar: NgbCalendar,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.adjustmentDate = this.calendar.getToday();
    this.loadMembers();
    this.userDetails = JSON.parse(localStorage.getItem("userDetails"));
    this.menuId = 27000;

    this.id = 0;

    this.route.paramMap.subscribe((params) => {
      const expID = +params.get("id");
      if (expID) {
        this.getAdjustment(expID);
      }
    });
    this.adjustmentDate = this.calendar.getToday();
  }

  cancelAdjustment() {
    this.router.navigate(["./", { outlets: { pages: ["adjustment"] } }], {
      relativeTo: this.route.parent,
    });
  }

  getAdjustment(id: number) {
    this.adjustmentService.getAdjustment(id).subscribe(
      (adjustment: Adjustment) => this.editAdjustment(adjustment),
      (err: any) => {
        this.displayFeedback(
          "error",
          "Error!",
          "Something went wrong. Try again!"
        );
        this.router.navigate(["./", { outlets: { pages: ["adjustment"] } }], {
          relativeTo: this.route.parent,
        });
      }
    );
  }

  editAdjustment(adjustment: Adjustment) {
    console.log(adjustment);
    this.adjustmentForm.patchValue({
      id: adjustment.id,
      adjustmentNum: adjustment.adjustmentNum,
      adjustmentDate: adjustment.adjustmentDate,
      member: { id: adjustment.member, name: adjustment.memberName },
      memberName: adjustment.memberName,
      prevBalAmt: adjustment.prevBalAmt,
      currentBalAmt: adjustment.currentBalAmt,
      totalBalAmt: adjustment.totalBalAmt,
      amount: adjustment.amount,
      notes: adjustment.notes,
      status: adjustment.status,
    });
    this.id = adjustment.id;
    this.member = adjustment.member;
    this.memberName = adjustment.memberName;

    this.adjustmentDate = this.ngbDateAdapter.fromModel(
      new Date(adjustment.adjustmentDate)
    );
  }

  saveAdjustment(reset: boolean) {
    const adjustment: Adjustment = {
      id: this.adjustmentForm.value.id,
      adjustmentNum: this.adjustmentForm.value.adjustmentNum,
      adjustmentDate: this.adjustmentForm.value.adjustmentDate,
      member: this.adjustmentForm.value.member.id,
      memberName: this.adjustmentForm.value.member.memberName,
      prevBalAmt: this.adjustmentForm.value.prevBalAmt,
      currentBalAmt: this.adjustmentForm.value.currentBalAmt,
      totalBalAmt: this.adjustmentForm.value.totalBalAmt,
      amount: this.adjustmentForm.value.amount,
      notes: this.adjustmentForm.value.notes,
      status: this.adjustmentForm.value.status,
    };

    if (adjustment.id < 1) {
      this.adjustmentService.add(adjustment).subscribe(
        (response: HttpResponse) => {
          if (response.result === HttpResult.Success) {
            this.displayFeedback("success", "Saved Successfully!", "");
            if (reset) {
              this.resetAdjustment();
            } else {
              this.router.navigate(
                ["./", { outlets: { pages: ["adjustment"] } }],
                { relativeTo: this.route.parent }
              );
            }
          } else {
            this.displayFeedback(
              "warning",
              response.result + "!",
              response.message
            );
          }
        },
        (error) => {
          this.displayFeedback("error", "Error!", error);
        }
      );
    } else {
      this.adjustmentService.modify(adjustment).subscribe(
        (response: HttpResponse) => {
          if (response.result === HttpResult.Success) {
            this.displayFeedback("success", "Saved Successfully!", "");
            if (reset) {
              this.resetAdjustment();
            } else {
              this.router.navigate(
                ["./", { outlets: { pages: ["adjustment"] } }],
                { relativeTo: this.route.parent }
              );
            }
          } else {
            this.displayFeedback(
              "warning",
              response.result + "!",
              response.message
            );
          }
        },
        (error) => {
          this.displayFeedback("error", "Error!", error);
        }
      );
    }
  }

  getMemberDetails() {
    if (this.adjustmentForm.value.member != null) {
      this.memberService
        .getMemberBalance(
          this.adjustmentForm.value.member.id,
          this.adjustmentDate.year +
            "-" +
            this.adjustmentDate.month +
            "-" +
            this.adjustmentDate.day
        )
        .subscribe(
          (response) => {
            this.adjustmentForm.patchValue({
              prevBalAmt: response.memberBalance.prevBalAmt,
              currentBalAmt: response.memberBalance.currentBalAmt,
              totalBalAmt: response.memberBalance.totalBalAmt,
            });
          },
          (err: any) => console.log(err)
        );
    }
  }

  resetAdjustment() {
    this.adjustmentForm.reset({
      id: 0,
      adjustmentNum: "",
      adjustmentDate: new Date(),
      member: { id: 0, name: "" },
      memberName: "",
      prevBalAmt: 0,
      currentBalAmt: 0,
      totalBalAmt: 0,
      amount: 0,
      notes: "",
      status: false,
    });
  }

  private loadMembers() {
    this.member$ = concat(
      of([]),
      this.memberInput$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => (this.memberLoading = true)),
        switchMap((term) =>
          this.memberService.getMembersList(term).pipe(
            catchError(() => of([])),
            tap(() => (this.memberLoading = false))
          )
        )
      )
    );
  }

  hasAdd() {
    let privilage = false;

    this.userDetails.menus.forEach((value) => {
      if (value.id === this.menuId) {
        if (value.privilegeAdd && this.adjustmentForm.value.id < 1)
          privilage = true;
        else if (value.privilegeModify && this.adjustmentForm.value.id > 0)
          privilage = true;
      }
    });

    return privilage;
  }

  displayFeedback(stype: SweetAlertType, stitle: string, stext: string) {
    Swal.fire({
      toast: true,
      type: stype,
      title: stitle,
      text: stext,
      showConfirmButton: false,
      position: "top",
      timer: 3000,
    });
  }

  displayHTMLFeedback(stype: SweetAlertType, stitle: string, shtml: string) {
    Swal.fire({
      type: stype,
      title: stitle,
      html: shtml,
      showConfirmButton: true,
    });
  }
}
