import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormControl, FormGroup, FormBuilder, FormArray } from "@angular/forms";
import { Observable, Subject, concat, of } from "rxjs";
import {
  debounceTime,
  map,
  distinctUntilChanged,
  tap,
  switchMap,
  catchError,
} from "rxjs/operators";
import Swal, { SweetAlertType } from "sweetalert2";
import { User } from "../../core/models/system/user";
import { Role } from "../../core/models/system/role";
import { UsersService } from "../../core/services/system/users.service";
import { RolesService } from "../../core/services/system/roles.service";
import {
  HttpResponse,
  HttpResult,
} from "src/app/core/models/utils/http-response";

@Component({
  selector: "app-user-page",
  templateUrl: "./user-page.component.html",
  styleUrls: ["./user-page.component.css"],
})
export class UserPageComponent implements OnInit {
  id: number;
  roles$: Observable<Role[]>;

  public userForm: FormGroup;
  public branchList: FormArray;

  constructor(
    private userService: UsersService,
    private roleService: RolesService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.roles$ = this.roleService.getRoles();
    this.userForm = new FormGroup({
      id: new FormControl(""),
      name: new FormControl(""),
      email: new FormControl(""),
      password: new FormControl(""),
      role: new FormControl(0),
      roleName: new FormControl(""),
      active: new FormControl(true),
    });

    this.route.paramMap.subscribe((params) => {
      const expID = params.get("id");
      if (expID) {
        this.getUser(expID);
      } else {
        this.getUser("0");
      }
    });
  }

  cancelUser() {
    this.router.navigate(["./", { outlets: { pages: ["users"] } }], {
      relativeTo: this.route.parent,
    });
  }

  getUser(id: string) {
    this.userService.getUser(id).subscribe(
      (user: User) => this.editUser(user),
      (err: any) => {
        this.displayFeedback(
          "error",
          "Error!",
          "Something went wrong. Try again!"
        );
        this.router.navigate(["./", { outlets: { pages: ["users"] } }], {
          relativeTo: this.route.parent,
        });
      }
    );
  }

  editUser(user: User) {
    console.log(user);
    if (user != null) {
      this.userForm.patchValue({
        id: user.id,
        name: user.name,
        email: user.email,
        password: user.password,
        role: user.role,
        roleName: user.roleName,
        active: user.active,
      });
      if (user.id !== "" && user.role !== 0) {
        this.id = 1;
      } else {
        this.id = 0;
      }
    } else {
      this.id = 0;
    }
  }

  saveUser(reset: boolean) {
    const user: User = {
      id: this.userForm.value.id,
      name: this.userForm.value.name,
      email: this.userForm.value.email,
      password: this.userForm.value.password,
      role: this.userForm.value.role,
      roleName: this.userForm.value.roleName,
      active: this.userForm.value.active,
      createdBy: "",
      editedBy: "",
    };
    if (this.id < 1) {
      console.log(user);
      this.userService.addUser(user).subscribe(
        (response: HttpResponse) => {
          if (response.result === HttpResult.Success) {
            this.displayFeedback("success", "Saved Successfully!", "");
            if (reset) {
              this.resetUser();
            } else {
              this.router.navigate(["./", { outlets: { pages: ["users"] } }], {
                relativeTo: this.route.parent,
              });
            }
          } else {
            this.displayHTMLFeedback(
              "warning",
              response.result + "!",
              response.message
            );
          }
        },
        (error) => {
          this.displayFeedback("error", "Error!", error);
        }
      );
    } else {
      this.userService.modifyUser(user).subscribe(
        (response: HttpResponse) => {
          if (response.result === HttpResult.Success) {
            this.displayFeedback("success", "Saved Successfully!", "");
            if (reset) {
              this.resetUser();
            } else {
              this.router.navigate(["./", { outlets: { pages: ["users"] } }], {
                relativeTo: this.route.parent,
              });
            }
          } else {
            this.displayHTMLFeedback(
              "warning",
              response.result + "!",
              response.message
            );
          }
        },
        (error) => {
          this.displayFeedback("error", "Error!", error);
        }
      );
    }
  }

  resetUser() {
    this.getUser("0");
  }

  resetPassword() {
    if (this.userForm.value.id === null || this.userForm.value.id === "") {
      this.displayFeedback(
        "warning",
        "Please select a user to reset password!",
        ""
      );
    } else {
      this.router.navigate(
        [
          "./",
          {
            outlets: { pages: ["reset-user-password", this.userForm.value.id] },
          },
        ],
        { relativeTo: this.route.parent }
      );
    }
  }

  displayFeedback(stype: SweetAlertType, stitle: string, stext: string) {
    Swal.fire({
      toast: true,
      type: stype,
      title: stitle,
      text: stext,
      showConfirmButton: false,
      position: "top",
      timer: 3000,
    });
  }

  displayHTMLFeedback(stype: SweetAlertType, stitle: string, shtml: string) {
    Swal.fire({
      type: stype,
      title: stitle,
      html: shtml,
      showConfirmButton: true,
    });
  }
}
