import { Component, OnInit, ContentChild } from "@angular/core";
import { Observable } from "rxjs";
import { NgbDateStruct, NgbCalendar } from "@ng-bootstrap/ng-bootstrap";
import Swal, { SweetAlertType } from "sweetalert2";
import * as XLSX from "xlsx";
import { ViewChild, ElementRef } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";

import { UserDetails } from "src/app/core/models/system/user-details";
import { AdvanceRegister } from "src/app/core/models/reports/advance-register";
import { AdvanceRegisterSearch } from "src/app/core/models/reports/advance-register-search";
import { ReportGeneratorService } from "src/app/core/services/reports/report-generator.services";
import { Category } from "src/app/core/models/masters/category";
import { CategoryService } from "src/app/core/services/masters/category.service";
import { FormControl, FormGroup } from "@angular/forms";

@Component({
  selector: "app-advance-register",
  templateUrl: "./advance-register.component.html",
  styleUrls: ["./advance-register.component.css"],
})
export class AdvanceRegisterComponent implements OnInit {
  @ViewChild("table", { static: true }) table: ElementRef;

  fromDate: NgbDateStruct;
  toDate: NgbDateStruct;
  categories$: Observable<Category[]>;

  userDetails: UserDetails;
  menuId: number;
  advanceRegister: AdvanceRegister;
  listCount: any;

  advanceRegisterForm = new FormGroup({
    code: new FormControl(""),
    name: new FormControl(""),
    phone: new FormControl(""),
    email: new FormControl(""),
    category: new FormControl(0),
  });

  constructor(
    private calendar: NgbCalendar,
    private advanceRegisterService: ReportGeneratorService,
    private categoryService: CategoryService,
    private spinnerService: NgxSpinnerService
  ) {
    this.advanceRegister = { registers: null, totalAmount: 0 };
  }

  ngOnInit() {
    this.fromDate = this.calendar.getToday();
    this.toDate = this.calendar.getToday();
    this.categories$ = this.categoryService.getCategorys();
    this.menuId = 32000;
    this.listCount = 0;
    this.userDetails = JSON.parse(localStorage.getItem("userDetails"));
  }

  resetFilters() {
    this.advanceRegisterForm.reset({
      code: "",
      name: "",
      phone: "",
      email: "",
      category: 0,
    });
  }

  setAdvanceRegisterList() {
    const searchFields: AdvanceRegisterSearch = {
      code: this.advanceRegisterForm.value.code,
      name: this.advanceRegisterForm.value.name,
      phone: this.advanceRegisterForm.value.phone,
      email: this.advanceRegisterForm.value.email,
      category: this.advanceRegisterForm.value.category,
    };
    this.spinnerService.show();

    this.advanceRegisterService.getAdvanceRegister(searchFields).subscribe(
      (response: AdvanceRegister) => {
        this.advanceRegister = response;
        this.listCount = this.advanceRegister.registers.length;
        this.spinnerService.hide();
      },
      (err: any) => {
        console.log(err);
        this.spinnerService.hide();
      }
    );
    if (this.listCount === null) {
      this.listCount = 0;
    }
  }

  hasView() {
    let privilage = false;
    this.userDetails.menus.forEach((value) => {
      if (value.id === this.menuId) {
        if (value.privilegeView || value.privilegeModify) privilage = true;
      }
    });

    return privilage;
  }

  hasExport() {
    let privilage = false;

    this.userDetails.menus.forEach((value) => {
      if (value.id === this.menuId) {
        if (value.privilegeExport) privilage = true;
      }
    });

    return privilage;
  }

  exportToExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      this.table.nativeElement
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "AdvanceRegister");

    XLSX.writeFile(wb, "AdvanceRegister.xlsx");
  }

  displayFeedback(stype: SweetAlertType, stitle: string, stext: string) {
    Swal.fire({
      toast: true,
      type: stype,
      title: stitle,
      text: stext,
      showConfirmButton: false,
      position: "top",
      timer: 3000,
    });
  }
}
