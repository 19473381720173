import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { LoginComponent } from "./login/login.component";
import { HeaderComponent } from "./header/header.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { FooterComponent } from "./footer/footer.component";
import { DashbaordComponent } from "./dashbaord/dashbaord.component";
import { HomeComponent } from "./home/home.component";
import { LogoutComponent } from "./logout/logout.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { ChangePasswordComponent } from "./change-password/change-password.component";
import { InputDateComponent } from "./input-date/input-date.component";

@NgModule({
  declarations: [
    LoginComponent,
    HeaderComponent,
    NavbarComponent,
    FooterComponent,
    DashbaordComponent,
    HomeComponent,
    LogoutComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent,
    InputDateComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
  ],
  exports: [
    LoginComponent,
    HeaderComponent,
    NavbarComponent,
    FooterComponent,
    LogoutComponent,
    HomeComponent,
    InputDateComponent,
  ],
})
export class ComponentsModule {}
