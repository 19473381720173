import { Component, OnInit } from "@angular/core";
import { ReportGeneratorService } from "../../services/reports/report-generator.services";

@Component({
  selector: "app-dashbaord",
  templateUrl: "./dashbaord.component.html",
  styleUrls: ["./dashbaord.component.css"],
})
export class DashbaordComponent implements OnInit {
  dashboard: any = {
    totalMembers: 0,
    activeMembers: 0,
    inactiveMembers: 0,
    otherMembers: 0,
  };
  constructor(private reportGeneratorService: ReportGeneratorService) {}

  ngOnInit() {
    this.reportGeneratorService.getDashboard().subscribe(
      (response: any) => {
        this.dashboard = response;
      },
      (err: any) => console.log(err)
    );
  }
}
