import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { SweetAlert2Module } from "@sweetalert2/ngx-sweetalert2";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgxSpinnerModule } from "ngx-spinner";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { RolePageComponent } from "./role-page/role-page.component";
import { RoleListPageComponent } from "./role-list-page/role-list-page.component";
import { UserListPageComponent } from "./user-list-page/user-list-page.component";
import { UserPageComponent } from "./user-page/user-page.component";
import { SettingsPageComponent } from './settings-page/settings-page.component';

@NgModule({
  declarations: [
    RolePageComponent,
    RoleListPageComponent,
    UserListPageComponent,
    UserPageComponent,
    SettingsPageComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SweetAlert2Module,
    NgbModule.forRoot(),
    NgSelectModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
  ],
  exports: [],
})
export class SystemModule {}
