import {
  Injectable,
  Component,
  OnInit,
  forwardRef,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import {
  NgbCalendar,
  NgbDateStruct,
  NgbDateParserFormatter,
  NgbDateAdapter,
} from "@ng-bootstrap/ng-bootstrap";

@Injectable({
  providedIn: "root",
})
export class InputDateAdapter extends NgbDateAdapter<Date> {
  fromModel(date: Date): NgbDateStruct | null {
    date = new Date(date);
    return date
      ? {
          year: date.getFullYear(),
          month: date.getMonth() + 1,
          day: date.getDate(),
        }
      : null;
  }

  toModel(date: NgbDateStruct): Date | null {
    return date
      ? new Date(Date.UTC(date.year, date.month - 1, date.day, 0, 0, 0))
      : null;
  }
}

@Injectable()
export class InputDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = "/";

  parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date
      ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year
      : "";
  }
}

@Component({
  selector: "app-input-date",
  templateUrl: "./input-date.component.html",
  styleUrls: ["./input-date.component.css"],
  providers: [
    { provide: NgbDateParserFormatter, useClass: InputDateParserFormatter },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputDateComponent),
      multi: true,
    },
  ],
})
export class InputDateComponent implements OnInit, ControlValueAccessor {
  @Output() dateChange = new EventEmitter();

  _value: NgbDateStruct;
  _focused: boolean;
  _disabled: boolean;
  _readonly: boolean;

  _type: string;
  _display: string;
  _today: NgbDateStruct;

  onChanged: any = () => {};
  onTouched: any = () => {};

  constructor(
    private calendar: NgbCalendar,
    private inputDateAdapter: InputDateAdapter
  ) {}

  ngOnInit(): void {
    this._value = this.calendar.getToday();
    this._today = this.calendar.getToday();
  }

  @Input()
  public set display(value: string) {
    this._display = value;
  }

  @Input()
  public set readonly(value: boolean) {
    if(value === null){
      this._readonly = false;
    }else{
    this._readonly = value;
    }
  }

  writeValue(value: Date): void {
    this._value = this.inputDateAdapter.fromModel(value);
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this._disabled = isDisabled;
  }

  onChange(event) {
    this.onChanged(this.inputDateAdapter.toModel(event));
    this.dateChange.emit(event);
  }

  onFocus(event) {
    this._focused = true;
  }

  onBlur(event) {
    if (this._value.year) {
      this.onChange(this._value);
    } else {
      this._value = this.calendar.getToday();
    }
    this.onTouched();
    this._focused = false;
  }
}
