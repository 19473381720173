import { Component, OnInit, ContentChild } from "@angular/core";
import { concat, Observable, of, Subject } from "rxjs";
import { NgbDateStruct, NgbCalendar } from "@ng-bootstrap/ng-bootstrap";
import Swal, { SweetAlertType } from "sweetalert2";
import * as XLSX from "xlsx";
import { ViewChild, ElementRef } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import {
  debounceTime,
  map,
  distinctUntilChanged,
  tap,
  switchMap,
  catchError,
} from "rxjs/operators";

import { UserDetails } from "src/app/core/models/system/user-details";
import {
  PaymentSummary,
  PaymentSummarys,
} from "src/app/core/models/reports/payment-summary";
import { PaymentSummarySearch } from "src/app/core/models/reports/payment-summary-search";
import { ReportGeneratorService } from "src/app/core/services/reports/report-generator.services";
import { FormControl, FormGroup } from "@angular/forms";
import { Category } from "src/app/core/models/masters/category";
import { CategoryService } from "src/app/core/services/masters/category.service";

@Component({
  selector: "app-payment-summary",
  templateUrl: "./payment-summary.component.html",
  styleUrls: ["./payment-summary.component.css"],
})
export class PaymentSummaryComponent implements OnInit {
  @ViewChild("table", { static: true }) table: ElementRef;

  fromDate: NgbDateStruct;
  toDate: NgbDateStruct;
  categories$: Observable<Category[]>;

  userDetails: UserDetails;
  menuId: number;
  paymentSummary: PaymentSummarys;
  listCount: any;
  exportHeading: string;
  exportFileName: string;

  paymentSummaryForm = new FormGroup({
    fromDate: new FormControl(new Date()),
    toDate: new FormControl(new Date()),
    category: new FormControl(0),
  });

  constructor(
    private calendar: NgbCalendar,
    private memberStatementService: ReportGeneratorService,
    private categoryService: CategoryService,
    private spinnerService: NgxSpinnerService
  ) {
    this.paymentSummary = { payments: null, totalAmount: 0 };
  }

  ngOnInit() {
    this.fromDate = this.calendar.getToday();
    this.toDate = this.calendar.getToday();
    this.categories$ = this.categoryService.getCategorys();
    this.menuId = 31000;
    this.listCount = 0;
    this.userDetails = JSON.parse(localStorage.getItem("userDetails"));
  }

  resetFilters() {
    this.paymentSummaryForm.reset({
      fromDate: new Date(),
      toDate: new Date(),
      category: 0,
    });
    this.exportHeading = "";
    this.exportFileName = "";
  }

  setPaymentSummaryList() {
    const searchFields: PaymentSummarySearch = {
      fromDate: this.paymentSummaryForm.value.fromDate,
      toDate: this.paymentSummaryForm.value.toDate,
      category: this.paymentSummaryForm.value.category,
    };
    this.spinnerService.show();

    this.memberStatementService.getPaymentSummary(searchFields).subscribe(
      (response: PaymentSummarys) => {
        console.log(this.paymentSummaryForm.value.fromDate);
        console.log(this.paymentSummaryForm.value.toDate);
        var months = new Array(
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec"
        );
        this.exportHeading =
          "Payment History : " +
          this.paymentSummaryForm.value.fromDate.getDate() +
          "-" +
          months[this.paymentSummaryForm.value.fromDate.getMonth()] +
          "-" +
          this.paymentSummaryForm.value.fromDate.getFullYear() +
          " to " +
          this.paymentSummaryForm.value.fromDate.getDate() +
          "-" +
          months[this.paymentSummaryForm.value.fromDate.getMonth()] +
          "-" +
          this.paymentSummaryForm.value.fromDate.getFullYear();
        this.exportFileName =
          months[this.paymentSummaryForm.value.fromDate.getMonth()] +
          "-" +
          this.paymentSummaryForm.value.fromDate.getFullYear();
        this.paymentSummary = response;
        this.listCount = this.paymentSummary.payments.length;
        this.spinnerService.hide();
      },
      (err: any) => {
        console.log(err);
        this.spinnerService.hide();
      }
    );
    if (this.listCount === null) {
      this.listCount = 0;
    }
  }

  hasView() {
    let privilage = false;
    this.userDetails.menus.forEach((value) => {
      if (value.id === this.menuId) {
        if (value.privilegeView || value.privilegeModify) privilage = true;
      }
    });

    return privilage;
  }

  hasExport() {
    let privilage = false;

    this.userDetails.menus.forEach((value) => {
      if (value.id === this.menuId) {
        if (value.privilegeExport) privilage = true;
      }
    });

    return privilage;
  }

  exportToExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      this.table.nativeElement
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, this.exportFileName);

    XLSX.writeFile(wb, this.exportFileName + ".xlsx");
  }

  displayFeedback(stype: SweetAlertType, stitle: string, stext: string) {
    Swal.fire({
      toast: true,
      type: stype,
      title: stitle,
      text: stext,
      showConfirmButton: false,
      position: "top",
      timer: 3000,
    });
  }
}
