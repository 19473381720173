import { Component, OnInit, ContentChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";
import Swal, { SweetAlertType } from "sweetalert2";
import * as XLSX from "xlsx";
import { ViewChild, ElementRef } from "@angular/core";
import { NgbPagination } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";

import {
  HttpResponse,
  HttpResult,
} from "src/app/core/models/utils/http-response";
import { UserDetails } from "src/app/core/models/system/user-details";
import { Purchase } from "src/app/core/models/transactions/purchase";
import { PurchaseService } from "src/app/core/services/transactions/purchase.service";

@Component({
  selector: "app-purchase-list-page",
  templateUrl: "./purchase-list-page.component.html",
  styleUrls: ["./purchase-list-page.component.css"],
})
export class PurchaseListPageComponent implements OnInit {
  @ViewChild("table", { static: true }) table: ElementRef;
  @ContentChild(NgbPagination, /* TODO: add static flag */ null)
  pagination: NgbPagination;

  page = 1;
  pageSize = 25;
  collectionSize = 0;
  listItemFrom = 1;
  listItemTo = 25;
  searchBy = "purchase_number";
  searchText = "";

  userDetails: UserDetails;
  menuId: number;
  purchases: Purchase[];

  constructor(
    private purchaseService: PurchaseService,
    private router: Router,
    private route: ActivatedRoute,
    private spinnerService: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.pageSize = 25;
    this.searchBy = "purchase_number";
    this.searchText = "";
    this.collectionSize = 0;
    this.listItemFrom = 1;
    this.listItemTo = 25;

    this.setPurchaseList();
    this.userDetails = JSON.parse(localStorage.getItem("userDetails"));
    this.menuId = 28000;
  }

  loadPurchase(id: number) {
    this.router.navigate(["./", { outlets: { pages: ["purchase", id] } }], {
      relativeTo: this.route.parent,
    });
  }

  deletePurchase(id: number) {
    this.purchaseService.delete(id).subscribe(
      (response: HttpResponse) => {
        if (response.result === HttpResult.Success) {
          this.displayFeedback("success", "Deleted Successfully!", "");
          this.setPurchaseList();
        } else {
          this.displayFeedback(
            "warning",
            response.result + "!",
            response.message
          );
        }
      },
      (error) => {
        this.displayFeedback("error", "Error!", error);
      }
    );
  }

  getPageFromService(pages) {
    this.page = pages;
    this.setPurchaseList();
  }

  setPageCount(pageSizes) {
    this.pageSize = pageSizes;
    this.setPurchaseList();
  }

  onSearchTextChange() {
    this.setPurchaseList();
  }

  setPurchaseList() {
    this.spinnerService.show();
    this.purchaseService
      .getPurchaseBySearch(
        this.page,
        this.pageSize,
        this.searchBy,
        this.searchText
      )
      .subscribe(
        (response) => {
          this.collectionSize = response.totalItems;
          this.listItemFrom = response.listItemFrom;
          this.listItemTo = response.listItemTo;
          this.purchases = response.purchases as Purchase[];
          this.spinnerService.hide();
        },
        (err: any) => {
          console.log(err);
          this.spinnerService.hide();
        }
      );
  }

  hasAdd() {
    let privilage = false;

    this.userDetails.menus.forEach((value) => {
      if (value.id === this.menuId) {
        if (value.privilegeAdd) privilage = true;
      }
    });

    return privilage;
  }

  hasView() {
    let privilage = false;
    this.userDetails.menus.forEach((value) => {
      if (value.id === this.menuId) {
        if (value.privilegeView || value.privilegeModify) privilage = true;
      }
    });

    return privilage;
  }

  hasDelete() {
    let privilage = false;

    this.userDetails.menus.forEach((value) => {
      if (value.id === this.menuId) {
        if (value.privilegeDelete) privilage = true;
      }
    });

    return privilage;
  }

  hasExport() {
    let privilage = false;

    this.userDetails.menus.forEach((value) => {
      if (value.id === this.menuId) {
        if (value.privilegeExport) privilage = true;
      }
    });

    return privilage;
  }

  hasAudit() {
    let privilage = false;

    this.userDetails.menus.forEach((value) => {
      if (value.id === this.menuId) {
        if (value.privilegeAudit) privilage = true;
      }
    });

    return privilage;
  }

  auditPurchase(id: number) {
    alert("Audit Display");
  }

  trackPurchase(index, item) {
    return item;
  }

  exportToExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      this.table.nativeElement
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Purchases");

    XLSX.writeFile(wb, "Purchases.xlsx");
  }

  displayFeedback(stype: SweetAlertType, stitle: string, stext: string) {
    Swal.fire({
      toast: true,
      type: stype,
      title: stitle,
      text: stext,
      showConfirmButton: false,
      position: "top",
      timer: 3000,
    });
  }
}
