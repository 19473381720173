import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
  userName = "Administrator";

  profileImage: SafeResourceUrl;

  constructor(private readonly sanitizer: DomSanitizer) {
    this.profileImage = this.sanitizer.bypassSecurityTrustResourceUrl(
      "../../../../assets/images/avatar.png"
    );
  }

  ngOnInit() {
    this.userName = JSON.parse(localStorage.getItem("userDetails")).name;
  }
}
