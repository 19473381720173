import { Component, OnInit, ContentChild } from "@angular/core";
import { concat, Observable, of, Subject } from "rxjs";
import { NgbDateStruct, NgbCalendar } from "@ng-bootstrap/ng-bootstrap";
import Swal, { SweetAlertType } from "sweetalert2";
import * as XLSX from "xlsx";
import { ViewChild, ElementRef } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import {
  debounceTime,
  map,
  distinctUntilChanged,
  tap,
  switchMap,
  catchError,
} from "rxjs/operators";

import { UserDetails } from "src/app/core/models/system/user-details";
import { MemberStatements } from "src/app/core/models/reports/member-statement";
import { MemberStatementSearch } from "src/app/core/models/reports/member-statement-search";
import { ReportGeneratorService } from "src/app/core/services/reports/report-generator.services";
import { FormControl, FormGroup } from "@angular/forms";
import { MemberList } from "src/app/core/models/masters/member-list";
import { MemberService } from "src/app/core/services/masters/member.service";

@Component({
  selector: "app-member-statement",
  templateUrl: "./member-statement.component.html",
  styleUrls: ["./member-statement.component.css"],
})
export class MemberStatementComponent implements OnInit {
  @ViewChild("table", { static: true }) table: ElementRef;

  fromDate: NgbDateStruct;
  toDate: NgbDateStruct;
  members$: MemberList[];

  member: number;
  memberName: string;

  userDetails: UserDetails;
  menuId: number;
  memberStatements: MemberStatements;
  listCount: any;

  memberStatementForm = new FormGroup({
    member: new FormControl({ id: 0, name: "" }),
    memberName: new FormControl(""),
    fromDate: new FormControl(new Date()),
    toDate: new FormControl(new Date()),
  });

  memberLoading = false;
  member$: Observable<MemberList[]>;
  memberInput$ = new Subject<string>();
  selectedMember: any;

  constructor(
    private calendar: NgbCalendar,
    private memberStatementService: ReportGeneratorService,
    private memberService: MemberService,
    private spinnerService: NgxSpinnerService
  ) {
    this.memberStatements = {
      member: 0,
      code: "",
      memberName: "",
      category: "",
      openingAmount: 0,
      statements: null,
      closingAmount: 0,
    };
  }

  ngOnInit() {
    this.fromDate = this.calendar.getToday();
    this.toDate = this.calendar.getToday();
    this.loadMembers();
    this.menuId = 31000;
    this.listCount = 0;
    this.userDetails = JSON.parse(localStorage.getItem("userDetails"));
  }

  resetFilters() {
    this.memberStatementForm.reset({
      member: { id: 0, name: "" },
      memberName: "",
      fromDate: new Date(),
      toDate: new Date(),
    });
  }

  private loadMembers() {
    this.member$ = concat(
      of([]),
      this.memberInput$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => (this.memberLoading = true)),
        switchMap((term) =>
          this.memberService.getMembersList(term).pipe(
            catchError(() => of([])),
            tap(() => (this.memberLoading = false))
          )
        )
      )
    );
  }

  setMemberStatementList() {
    if (this.memberStatementForm.value.member === null) {
      this.memberStatementForm.value.member = { id: 0, name: "" };
    } else {
      if (this.memberStatementForm.value.member === 0) {
        this.memberStatementForm.value.member = { id: 0, name: "" };
      }
    }
    if (this.memberStatementForm.value.member.id === 0) {
      this.displayFeedback("warning", "Please select a member!", "");
    } else {
      const searchFields: MemberStatementSearch = {
        member: this.memberStatementForm.value.member.id,
        fromDate: this.memberStatementForm.value.fromDate,
        toDate: this.memberStatementForm.value.toDate,
      };
      this.spinnerService.show();

      this.memberStatementService.getMemberStatements(searchFields).subscribe(
        (response: MemberStatements) => {
          this.memberStatements = response;
          this.listCount = this.memberStatements.statements.length;
          this.spinnerService.hide();
          console.log(this.memberStatements);
        },
        (err: any) => {
          console.log(err);
          this.spinnerService.hide();
        }
      );
      if (this.listCount === null) {
        this.listCount = 0;
      }
    }
  }

  hasView() {
    let privilage = false;
    this.userDetails.menus.forEach((value) => {
      if (value.id === this.menuId) {
        if (value.privilegeView || value.privilegeModify) privilage = true;
      }
    });

    return privilage;
  }

  hasExport() {
    let privilage = false;

    this.userDetails.menus.forEach((value) => {
      if (value.id === this.menuId) {
        if (value.privilegeExport) privilage = true;
      }
    });

    return privilage;
  }

  exportToExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      this.table.nativeElement
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "MemberStatements");

    XLSX.writeFile(wb, "MemberStatements.xlsx");
  }

  displayFeedback(stype: SweetAlertType, stitle: string, stext: string) {
    Swal.fire({
      toast: true,
      type: stype,
      title: stitle,
      text: stext,
      showConfirmButton: false,
      position: "top",
      timer: 3000,
    });
  }
}
