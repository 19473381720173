import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormControl, FormGroup, FormBuilder, FormArray } from "@angular/forms";
import Swal, { SweetAlertType } from "sweetalert2";
import { Role } from "../../core/models/system/role";
import { RoleMenu } from "../../core/models/system/role";
import {
  HttpResponse,
  HttpResult,
} from "src/app/core/models/utils/http-response";
import { RolesService } from "../../core/services/system/roles.service";
import { Observable } from "rxjs";
import { UserDetails } from "src/app/core/models/system/user-details";

@Component({
  selector: "app-role-page",
  templateUrl: "./role-page.component.html",
  styleUrls: ["./role-page.component.css"],
})
export class RolePageComponent implements OnInit {
  public roleForm: FormGroup;
  public menuList: FormArray;
  roles$: Observable<Role[]>;

  userDetails: UserDetails;
  menuId: number;
  constructor(
    private roleService: RolesService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.userDetails = JSON.parse(localStorage.getItem("userDetails"));
    this.menuId = 41000;

    this.roles$ = this.roleService.getRoles();
    this.roleForm = new FormGroup({
      id: new FormControl(0),
      name: new FormControl(""),
      active: new FormControl(true),
      roleMenus: this.formBuilder.array([]),
      allPrivilegeAdd: new FormControl(false),
      allprivilegeModify: new FormControl(false),
      allprivilegeDelete: new FormControl(false),
      allPrivilegeView: new FormControl(false),
      allprivilegeExport: new FormControl(false),
      allprivilegeAudit: new FormControl(false),
      copyRoleMenu: new FormControl(false),
      copyRole: new FormControl(0),
      copyRoleName: new FormControl(""),
    });

    this.menuList = this.roleForm.get("roleMenus") as FormArray;

    this.route.paramMap.subscribe((params) => {
      const expID = +params.get("id");
      if (expID) {
        this.getRole(expID);
      } else {
        this.getRole(0);
      }
    });
  }

  cancelRole() {
    this.router.navigate(["./", { outlets: { pages: ["roles"] } }], {
      relativeTo: this.route.parent,
    });
  }

  getRole(id: number) {
    this.roleService.getRole(id).subscribe(
      (role: Role) => this.editRole(role),
      (err: any) => {
        this.displayFeedback(
          "error",
          "Error!",
          "Something went wrong. Try again!"
        );
        this.router.navigate(["./", { outlets: { pages: ["roles"] } }], {
          relativeTo: this.route.parent,
        });
      }
    );
  }

  editRole(role: Role) {
    console.log(role);
    this.roleForm.patchValue({
      id: role.id,
      name: role.name,
      active: role.active,
      roleMenus: [],
    });
    this.createFormMenus(role.roleMenus);
  }

  saveRole(reset: boolean) {
    const role: Role = {
      id: this.roleForm.value.id,
      name: this.roleForm.value.name,
      active: this.roleForm.value.active,
      roleMenus: this.roleForm.value.roleMenus,
      createdBy: "",
      editedBy: "",
    };
    if (role.id < 1) {
      console.log(role);
      this.roleService.addRole(role).subscribe(
        (response: HttpResponse) => {
          if (response.result === HttpResult.Success) {
            this.displayFeedback("success", "Saved Successfully!", "");
            if (reset) {
              this.resetRole();
            } else {
              this.router.navigate(["./", { outlets: { pages: ["roles"] } }], {
                relativeTo: this.route.parent,
              });
            }
          } else {
            this.displayHTMLFeedback(
              "warning",
              response.result + "!",
              response.message
            );
          }
        },
        (error) => {
          this.displayFeedback("error", "Error!", error);
        }
      );
    } else {
      this.roleService.modifyRole(role).subscribe(
        (response: HttpResponse) => {
          if (response.result === HttpResult.Success) {
            this.displayFeedback("success", "Saved Successfully!", "");
            if (reset) {
              this.resetRole();
            } else {
              this.router.navigate(["./", { outlets: { pages: ["roles"] } }], {
                relativeTo: this.route.parent,
              });
            }
          } else {
            this.displayHTMLFeedback(
              "warning",
              response.result + "!",
              response.message
            );
          }
        },
        (error) => {
          this.displayFeedback("error", "Error!", error);
        }
      );
    }
  }

  resetRole() {
    for (let i = this.roleForm.value.roleMenus.length - 1; i >= 0; i--) {
      this.menuList.removeAt(i);
    }
    this.getRole(0);
  }

  get roleMenuFormGroup() {
    return this.roleForm.get("roleMenus") as FormArray;
  }

  createFormMenus(menus: RoleMenu[]) {
    console.log(menus);
    for (const menu of menus) {
      this.menuList.push(
        this.createRoleMenuWithValues(
          menu.id,
          menu.menu,
          menu.menuName,
          menu.menuHasScreen,
          menu.privilegeAdd,
          menu.privilegeModify,
          menu.privilegeDelete,
          menu.privilegeView,
          menu.privilegeExport,
          menu.privilegeAudit
        )
      );
    }
  }

  createRoleMenuWithValues(
    id: number,
    menu: number,
    menuName: string,
    menuHasScreen: boolean,
    privilegeAdd: boolean,
    privilegeModify: boolean,
    privilegeDelete: boolean,
    privilegeView: boolean,
    privilegeExport: boolean,
    privilegeAudit: boolean
  ): FormGroup {
    return this.formBuilder.group({
      id: new FormControl(id),
      menu: new FormControl(menu),
      menuName: new FormControl(menuName),
      menuHasScreen: new FormControl(menuHasScreen),
      privilegeAdd: new FormControl(privilegeAdd),
      privilegeModify: new FormControl(privilegeModify),
      privilegeDelete: new FormControl(privilegeDelete),
      privilegeView: new FormControl(privilegeView),
      privilegeExport: new FormControl(privilegeExport),
      privilegeAudit: new FormControl(privilegeAudit),
    });
  }

  trackMenu(index, item) {
    return item;
  }

  selectAllMenus(type: string) {
    let menuList: any[];
    menuList = this.roleForm.value.roleMenus;
    for (const menu of menuList) {
      if (type === "Add") {
        menu.privilegeAdd = this.roleForm.value.allPrivilegeAdd;
      } else if (type === "Modify") {
        menu.privilegeModify = this.roleForm.value.allprivilegeModify;
      } else if (type === "Delete") {
        menu.privilegeDelete = this.roleForm.value.allprivilegeDelete;
      } else if (type === "View") {
        menu.privilegeView = this.roleForm.value.allPrivilegeView;
      } else if (type === "Export") {
        menu.privilegeExport = this.roleForm.value.allprivilegeExport;
      } else if (type === "Audit") {
        menu.privilegeAudit = this.roleForm.value.allprivilegeAudit;
      }
    }
    this.roleForm.patchValue({
      roleMenus: menuList,
    });
  }

  copyRole() {
    if (this.roleForm.value.copyRole < 1) {
      this.displayHTMLFeedback("warning", "Please select a role" + "!", "");
    } else {
      this.roleService.getRole(this.roleForm.value.copyRole).subscribe(
        (role: Role) => this.setRole(role),
        (err: any) => {
          this.displayFeedback(
            "error",
            "Error!",
            "Something went wrong. Try again!"
          );
        }
      );
    }
  }

  setRole(role: Role) {
    console.log(role);
    for (let i = this.roleForm.value.roleMenus.length - 1; i >= 0; i--) {
      this.menuList.removeAt(i);
    }
    this.createFormMenus(role.roleMenus);
  }

  displayFeedback(stype: SweetAlertType, stitle: string, stext: string) {
    Swal.fire({
      toast: true,
      type: stype,
      title: stitle,
      text: stext,
      showConfirmButton: false,
      position: "top",
      timer: 3000,
    });
  }

  hasAdd() {
    let privilage = false;

    this.userDetails.menus.forEach((value) => {
      if (value.id === this.menuId) {
        if (value.privilegeAdd && this.roleForm.value.id < 1) privilage = true;
        else if (value.privilegeModify && this.roleForm.value.id > 0)
          privilage = true;
      }
    });

    return privilage;
  }

  displayHTMLFeedback(stype: SweetAlertType, stitle: string, shtml: string) {
    Swal.fire({
      type: stype,
      title: stitle,
      html: shtml,
      showConfirmButton: true,
    });
  }
}
