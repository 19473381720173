import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormControl, FormGroup } from "@angular/forms";
import { concat, Observable, of, Subject } from "rxjs";
import {
  debounceTime,
  map,
  distinctUntilChanged,
  tap,
  switchMap,
  catchError,
} from "rxjs/operators";

import Swal, { SweetAlertType } from "sweetalert2";
import {
  HttpResponse,
  HttpResult,
} from "src/app/core/models/utils/http-response";
import { UserDetails } from "src/app/core/models/system/user-details";
import { Member } from "../../core/models/masters/member";
import { MemberService } from "../../core/services/masters/member.service";
import { MemberList } from "src/app/core/models/masters/member-list";
import { Category } from "src/app/core/models/masters/category";
import { CategoryService } from "src/app/core/services/masters/category.service";

@Component({
  selector: "app-member-page",
  templateUrl: "./member-page.component.html",
  styleUrls: ["./member-page.component.css"],
})
export class MemberPageComponent implements OnInit {
  members$: Member[];
  categories$: Observable<Category[]>;
  userDetails: UserDetails;
  menuId: number;

  referrer: number;
  referrerName: string;

  memberForm = new FormGroup({
    id: new FormControl(0),
    code: new FormControl(""),
    name: new FormControl(""),
    phone: new FormControl(""),
    email: new FormControl(""),
    category: new FormControl(0),
    categoryName: new FormControl(""),
    referrer: new FormControl({ id: 0, name: "" }),
    referrerName: new FormControl(""),
    notes: new FormControl(""),
    active: new FormControl(true),
  });

  memberLoading = false;
  member$: Observable<MemberList[]>;
  memberInput$ = new Subject<string>();
  selectedMember: any;

  constructor(
    private memberService: MemberService,
    private categoryService: CategoryService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.userDetails = JSON.parse(localStorage.getItem("userDetails"));
    this.menuId = 12000;
    this.loadMembers();
    this.categories$ = this.categoryService.getCategorys();

    this.route.paramMap.subscribe((params) => {
      const id = +params.get("id");
      if (id) {
        this.getMember(id);
      }
    });
  }

  cancelMember() {
    this.router.navigate(["./", { outlets: { pages: ["member"] } }], {
      relativeTo: this.route.parent,
    });
  }

  getMember(id: number) {
    this.memberService.getMember(id).subscribe(
      (member: Member) => this.editMember(member),
      (err: any) => {
        this.displayFeedback(
          "error",
          "Error!",
          "Something went wrong. Try again!"
        );
        this.router.navigate(["./", { outlets: { pages: ["member"] } }], {
          relativeTo: this.route.parent,
        });
      }
    );
  }

  editMember(member: Member) {
    this.memberForm.patchValue({
      id: member.id,
      code: member.code,
      name: member.name,
      phone: member.phone,
      email: member.email,
      category: member.category,
      categoryName: member.categoryName,
      referrer: { id: member.referrer, name: member.referrerName },
      referrerName: member.referrerName,
      notes: member.notes,
      active: member.active,
    });
  }

  saveMember(reset: boolean) {
    const member: Member = {
      id: this.memberForm.value.id,
      code: this.memberForm.value.code,
      name: this.memberForm.value.name,
      phone: this.memberForm.value.phone,
      email: this.memberForm.value.email,
      category: this.memberForm.value.category,
      categoryName: this.memberForm.value.categoryName,
      referrer: this.memberForm.value.referrer.id,
      referrerName: this.memberForm.value.referrer.referrerName,
      notes: this.memberForm.value.notes,
      active: this.memberForm.value.active,
    };

    if (member.id < 1) {
      this.memberService.add(member).subscribe(
        (response: HttpResponse) => {
          if (response.result === HttpResult.Success) {
            this.displayFeedback("success", "Saved Successfully!", "");
            if (reset) {
              this.resetMember();
            } else {
              this.router.navigate(["./", { outlets: { pages: ["member"] } }], {
                relativeTo: this.route.parent,
              });
            }
          } else {
            this.displayHTMLFeedback(
              "warning",
              response.result + "!",
              response.message
            );
          }
        },
        (error) => {
          this.displayFeedback("error", "Error!", error);
        }
      );
    } else {
      this.memberService.modify(member).subscribe(
        (response: HttpResponse) => {
          if (response.result === HttpResult.Success) {
            this.displayFeedback("success", "Saved Successfully!", "");
            if (reset) {
              this.resetMember();
            } else {
              this.router.navigate(["./", { outlets: { pages: ["member"] } }], {
                relativeTo: this.route.parent,
              });
            }
          } else {
            this.displayHTMLFeedback(
              "warning",
              response.result + "!",
              response.message
            );
          }
        },
        (error) => {
          this.displayFeedback("error", "Error!", error);
        }
      );
    }
  }

  resetMember() {
    this.memberForm.reset({
      id: 0,
      code: "",
      name: "",
      phone: "",
      email: "",
      category: 0,
      categoryName: "",
      member: { id: 0, name: "" },
      memberName: "",
      notes: "",
      active: true,
    });
  }

  private loadMembers() {
    this.member$ = concat(
      of([]),
      this.memberInput$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => (this.memberLoading = true)),
        switchMap((term) =>
          this.memberService.getMembersList(term).pipe(
            catchError(() => of([])),
            tap(() => (this.memberLoading = false))
          )
        )
      )
    );
  }

  hasAdd() {
    let privilage = false;

    this.userDetails.menus.forEach((value) => {
      if (value.id === this.menuId) {
        if (value.privilegeAdd && this.memberForm.value.id < 1)
          privilage = true;
        else if (value.privilegeModify && this.memberForm.value.id > 0)
          privilage = true;
      }
    });

    return privilage;
  }

  displayFeedback(stype: SweetAlertType, stitle: string, stext: string) {
    Swal.fire({
      toast: true,
      type: stype,
      title: stitle,
      text: stext,
      showConfirmButton: false,
      position: "top",
      timer: 3000,
    });
  }

  displayHTMLFeedback(stype: SweetAlertType, stitle: string, shtml: string) {
    Swal.fire({
      type: stype,
      title: stitle,
      html: shtml,
      showConfirmButton: true,
    });
  }
}
