import { Component, OnInit, ContentChild } from "@angular/core";
import { concat, Observable, of, pipe, Subject } from "rxjs";
import { NgbDateStruct, NgbCalendar } from "@ng-bootstrap/ng-bootstrap";
import Swal, { SweetAlertType } from "sweetalert2";
import * as XLSX from "xlsx";
import { ViewChild, ElementRef } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";

import { UserDetails } from "src/app/core/models/system/user-details";
import { CollectionRegister } from "src/app/core/models/reports/collection-register";
import { CollectionRegisterSearch } from "src/app/core/models/reports/collection-register-search";
import { ReportGeneratorService } from "src/app/core/services/reports/report-generator.services";
import { FormControl, FormGroup } from "@angular/forms";

import { MonthlyProcess } from "src/app/core/models/transactions/monthly-process";
import { MonthlyProcessService } from "src/app/core/services/transactions/monthly-process.service";
import { ActivatedRoute } from "@angular/router";
import { map } from "rxjs/operators";

@Component({
  selector: "app-collection-register",
  templateUrl: "./collection-register.component.html",
  styleUrls: ["./collection-register.component.css"],
})
export class CollectionRegisterComponent implements OnInit {
  @ViewChild("table", { static: true }) table: ElementRef;

  userDetails: UserDetails;
  menuId: number;
  id: number;
  collectionRegister: CollectionRegister;
  listCount: any;
  processes$: Observable<MonthlyProcess[]>;
  exportHeading: string;
  exportFileName: string;

  collectionRegisterForm = new FormGroup({
    process: new FormControl(0),
    code: new FormControl(""),
    name: new FormControl(""),
    category: new FormControl(""),
  });

  constructor(
    private calendar: NgbCalendar,
    private memberStatementService: ReportGeneratorService,
    private monthlyProcessService: MonthlyProcessService,
    private spinnerService: NgxSpinnerService,
    private route: ActivatedRoute
  ) {
    this.collectionRegister = {
      id: 0,
      name: "",
      days: 0,
      quantity: 0,
      advanceAmount: 0,
      parcelAmount: 0,
      adjustmentAmount: 0,
      expenseAmount: 0,
      billAmount: 0,
      balanceAmount: 0,
      totalAmount: 0,
      collectionAmount: 0,
      finalAmount: 0,
      collections: null,
    };
  }

  ngOnInit() {
    this.processes$ = this.monthlyProcessService.getMonthlyProcesss();
    this.menuId = 31000;
    this.listCount = 0;
    this.userDetails = JSON.parse(localStorage.getItem("userDetails"));

    this.route.paramMap.subscribe((params) => {
      const expID = +params.get("id");
      if (expID) {
        this.setCollectionRegisterLists(expID);
      }
    });
  }

  resetFilters() {
    this.collectionRegisterForm.reset({
      process: 0,
      code: "",
      name: "",
      category: "",
    });
    this.exportHeading = "";
    this.exportFileName = "";
  }

  setCollectionRegisterLists(process: number) {
    this.collectionRegisterForm.patchValue({
      process: process,
    });
    const searchFields: CollectionRegisterSearch = {
      process: this.collectionRegisterForm.value.process,
      code: this.collectionRegisterForm.value.code,
      name: this.collectionRegisterForm.value.name,
      category: this.collectionRegisterForm.value.category,
    };
    this.spinnerService.show();

    this.memberStatementService.getCollectionRegister(searchFields).subscribe(
      (response: CollectionRegister) => {
        this.collectionRegister = response;
        this.exportHeading =
          "Mess Bill Collection for the Month of" + response.name;
        this.exportFileName = "Mess Bill Collection " + response.name;
        this.listCount = this.collectionRegister.collections.length;
        this.spinnerService.hide();
      },
      (err: any) => {
        console.log(err);
        this.spinnerService.hide();
      }
    );
    if (this.listCount === null) {
      this.listCount = 0;
    }
  }

  setCollectionRegisterList() {
    const searchFields: CollectionRegisterSearch = {
      process: this.collectionRegisterForm.value.process,
      code: this.collectionRegisterForm.value.code,
      name: this.collectionRegisterForm.value.name,
      category: this.collectionRegisterForm.value.category,
    };
    this.spinnerService.show();

    this.memberStatementService.getCollectionRegister(searchFields).subscribe(
      (response: CollectionRegister) => {
        this.collectionRegister = response;
        this.exportHeading =
          "Mess Bill Collection for the Month of" + response.name;
        this.exportFileName = "Mess Bill Collection " + response.name;
        this.listCount = this.collectionRegister.collections.length;
        this.spinnerService.hide();
      },
      (err: any) => {
        console.log(err);
        this.spinnerService.hide();
      }
    );
    if (this.listCount === null) {
      this.listCount = 0;
    }
  }

  hasView() {
    let privilage = false;
    this.userDetails.menus.forEach((value) => {
      if (value.id === this.menuId) {
        if (value.privilegeView || value.privilegeModify) privilage = true;
      }
    });

    return privilage;
  }

  hasExport() {
    let privilage = false;

    this.userDetails.menus.forEach((value) => {
      if (value.id === this.menuId) {
        if (value.privilegeExport) privilage = true;
      }
    });

    return privilage;
  }

  exportToExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      this.table.nativeElement
    );
    console.log(this.exportHeading);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, this.exportFileName);

    XLSX.writeFile(wb, this.exportFileName + ".xlsx");
  }

  displayFeedback(stype: SweetAlertType, stitle: string, stext: string) {
    Swal.fire({
      toast: true,
      type: stype,
      title: stitle,
      text: stext,
      showConfirmButton: false,
      position: "top",
      timer: 3000,
    });
  }
}
