import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormControl, FormGroup } from "@angular/forms";
import Swal, { SweetAlertType } from "sweetalert2";
import {
  HttpResponse,
  HttpResult,
} from "src/app/core/models/utils/http-response";
import { UserDetails } from "src/app/core/models/system/user-details";
import { Supplier } from "../../core/models/masters/supplier";
import { SupplierService } from "../../core/services/masters/supplier.service";

@Component({
  selector: "app-supplier-page",
  templateUrl: "./supplier-page.component.html",
  styleUrls: ["./supplier-page.component.css"],
})
export class SupplierPageComponent implements OnInit {
  userDetails: UserDetails;
  menuId: number;

  supplierForm = new FormGroup({
    id: new FormControl(0),
    name: new FormControl(""),
    phone: new FormControl(""),
    email: new FormControl(""),
    notes: new FormControl(""),
    active: new FormControl(true),
  });

  constructor(
    private supplierService: SupplierService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.userDetails = JSON.parse(localStorage.getItem("userDetails"));
    this.menuId = 14000;

    this.route.paramMap.subscribe((params) => {
      const id = +params.get("id");
      if (id) {
        this.getSupplier(id);
      }
    });
  }

  cancelSupplier() {
    this.router.navigate(["./", { outlets: { pages: ["supplier"] } }], {
      relativeTo: this.route.parent,
    });
  }

  getSupplier(id: number) {
    this.supplierService.getSupplier(id).subscribe(
      (supplier: Supplier) => this.editSupplier(supplier),
      (err: any) => {
        this.displayFeedback(
          "error",
          "Error!",
          "Something went wrong. Try again!"
        );
        this.router.navigate(["./", { outlets: { pages: ["supplier"] } }], {
          relativeTo: this.route.parent,
        });
      }
    );
  }

  editSupplier(supplier: Supplier) {
    this.supplierForm.patchValue({
      id: supplier.id,
      name: supplier.name,
      phone: supplier.phone,
      email: supplier.email,
      notes: supplier.notes,
      active: supplier.active,
    });
  }

  saveSupplier(reset: boolean) {
    const supplier: Supplier = this.supplierForm.value as Supplier;
    if (supplier.id < 1) {
      this.supplierService.add(supplier).subscribe(
        (response: HttpResponse) => {
          if (response.result === HttpResult.Success) {
            this.displayFeedback("success", "Saved Successfully!", "");
            if (reset) {
              this.resetSupplier();
            } else {
              this.router.navigate(
                ["./", { outlets: { pages: ["supplier"] } }],
                {
                  relativeTo: this.route.parent,
                }
              );
            }
          } else {
            this.displayHTMLFeedback(
              "warning",
              response.result + "!",
              response.message
            );
          }
        },
        (error) => {
          this.displayFeedback("error", "Error!", error);
        }
      );
    } else {
      this.supplierService.modify(supplier).subscribe(
        (response: HttpResponse) => {
          if (response.result === HttpResult.Success) {
            this.displayFeedback("success", "Saved Successfully!", "");
            if (reset) {
              this.resetSupplier();
            } else {
              this.router.navigate(
                ["./", { outlets: { pages: ["supplier"] } }],
                {
                  relativeTo: this.route.parent,
                }
              );
            }
          } else {
            this.displayHTMLFeedback(
              "warning",
              response.result + "!",
              response.message
            );
          }
        },
        (error) => {
          this.displayFeedback("error", "Error!", error);
        }
      );
    }
  }

  resetSupplier() {
    this.supplierForm.reset({
      id: 0,
      name: "",
      phone: "",
      email: "",
      notes: "",
      active: true,
    });
  }

  hasAdd() {
    let privilage = false;

    this.userDetails.menus.forEach((value) => {
      if (value.id === this.menuId) {
        if (value.privilegeAdd && this.supplierForm.value.id < 1)
          privilage = true;
        else if (value.privilegeModify && this.supplierForm.value.id > 0)
          privilage = true;
      }
    });

    return privilage;
  }

  displayFeedback(stype: SweetAlertType, stitle: string, stext: string) {
    Swal.fire({
      toast: true,
      type: stype,
      title: stitle,
      text: stext,
      showConfirmButton: false,
      position: "top",
      timer: 3000,
    });
  }

  displayHTMLFeedback(stype: SweetAlertType, stitle: string, shtml: string) {
    Swal.fire({
      type: stype,
      title: stitle,
      html: shtml,
      showConfirmButton: true,
    });
  }
}
