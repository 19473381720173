import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormControl, FormGroup } from "@angular/forms";
import Swal, { SweetAlertType } from "sweetalert2";
import {
  HttpResponse,
  HttpResult,
} from "src/app/core/models/utils/http-response";
import { UserDetails } from "src/app/core/models/system/user-details";
import { Setting } from "../../core/models/system/setting";
import { SettingService } from "../../core/services/system/setting.service";

@Component({
  selector: "app-settings-page",
  templateUrl: "./settings-page.component.html",
  styleUrls: ["./settings-page.component.css"],
})
export class SettingsPageComponent implements OnInit {
  userDetails: UserDetails;
  menuId: number;

  settingForm = new FormGroup({
    id: new FormControl(0),
    registrationFees: new FormControl(0),
    advanceAmount: new FormControl(0),
    parcelAmount: new FormControl(0),
    lateFeesPerc: new FormControl(0),
    lateFeesMinAmount: new FormControl(0),
    recurringExpenseDeduction: new FormControl(0),
  });

  constructor(
    private settingService: SettingService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.userDetails = JSON.parse(localStorage.getItem("userDetails"));
    this.menuId = 43000;
    this.getSetting();
  }

  getSetting() {
    this.settingService.getSetting().subscribe(
      (setting: Setting) => this.editSetting(setting),
      (err: any) => {
        this.displayFeedback(
          "error",
          "Error!",
          "Something went wrong. Try again!"
        );
      }
    );
  }

  editSetting(setting: Setting) {
    this.settingForm.patchValue({
      id: setting.id,
      registrationFees: setting.registrationFees,
      advanceAmount: setting.advanceAmount,
      parcelAmount: setting.parcelAmount,
      lateFeesPerc: setting.lateFeesPerc,
      lateFeesMinAmount: setting.lateFeesMinAmount,
      recurringExpenseDeduction: setting.recurringExpenseDeduction,
    });
  }

  saveSetting() {
    const setting: Setting = this.settingForm.value as Setting;
    if (setting.id < 1) {
      this.settingService.add(setting).subscribe(
        (response: HttpResponse) => {
          if (response.result === HttpResult.Success) {
            this.displayFeedback("success", "Saved Successfully!", "");
          } else {
            this.displayHTMLFeedback(
              "warning",
              response.result + "!",
              response.message
            );
          }
        },
        (error) => {
          this.displayFeedback("error", "Error!", error);
        }
      );
    } else {
      {
        this.settingService.modify(setting).subscribe(
          (response: HttpResponse) => {
            if (response.result === HttpResult.Success) {
              this.displayFeedback("success", "Saved Successfully!", "");
            } else {
              this.displayHTMLFeedback(
                "warning",
                response.result + "!",
                response.message
              );
            }
          },
          (error) => {
            this.displayFeedback("error", "Error!", error);
          }
        );
      }
    }
  }

  hasAdd() {
    let privilage = false;

    this.userDetails.menus.forEach((value) => {
      if (value.id === this.menuId) {
        if (value.privilegeAdd && this.settingForm.value.id < 1)
          privilage = true;
        else if (value.privilegeModify && this.settingForm.value.id > 0)
          privilage = true;
      }
    });

    return privilage;
  }

  displayFeedback(stype: SweetAlertType, stitle: string, stext: string) {
    Swal.fire({
      toast: true,
      type: stype,
      title: stitle,
      text: stext,
      showConfirmButton: false,
      position: "top",
      timer: 3000,
    });
  }

  displayHTMLFeedback(stype: SweetAlertType, stitle: string, shtml: string) {
    Swal.fire({
      type: stype,
      title: stitle,
      html: shtml,
      showConfirmButton: true,
    });
  }
}
