import { Component, OnInit, ContentChild } from "@angular/core";
import { concat, Observable, of, Subject } from "rxjs";
import { NgbDateStruct, NgbCalendar } from "@ng-bootstrap/ng-bootstrap";
import Swal, { SweetAlertType } from "sweetalert2";
import * as XLSX from "xlsx";
import { ViewChild, ElementRef } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import {
  debounceTime,
  map,
  distinctUntilChanged,
  tap,
  switchMap,
  catchError,
} from "rxjs/operators";

import { UserDetails } from "src/app/core/models/system/user-details";
import {
  RecurringExpenseSummary,
  RecurringExpenseSummarys,
} from "src/app/core/models/reports/recurring-expense-summary";
import { RecurringExpenseSummarySearch } from "src/app/core/models/reports/recurring-expense-summary-search";
import { ReportGeneratorService } from "src/app/core/services/reports/report-generator.services";
import { FormControl, FormGroup } from "@angular/forms";

@Component({
  selector: "app-recurring-expense-summary",
  templateUrl: "./recurring-expense-summary.component.html",
  styleUrls: ["./recurring-expense-summary.component.css"],
})
export class RecurringExpenseSummaryComponent implements OnInit {
  @ViewChild("table", { static: true }) table: ElementRef;

  fromDate: NgbDateStruct;
  toDate: NgbDateStruct;

  userDetails: UserDetails;
  menuId: number;
  recurringExpenseSummary: RecurringExpenseSummarys;
  listCount: any;

  recurringExpenseSummaryForm = new FormGroup({
    fromDate: new FormControl(new Date()),
    toDate: new FormControl(new Date()),
  });

  constructor(
    private calendar: NgbCalendar,
    private memberStatementService: ReportGeneratorService,
    private spinnerService: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.fromDate = this.calendar.getToday();
    this.toDate = this.calendar.getToday();
    this.menuId = 31000;
    this.listCount = 0;
    this.userDetails = JSON.parse(localStorage.getItem("userDetails"));
  }

  resetFilters() {
    this.recurringExpenseSummaryForm.reset({
      fromDate: new Date(),
      toDate: new Date(),
    });
  }

  setRecurringExpenseSummaryList() {
    const searchFields: RecurringExpenseSummarySearch = {
      fromDate: this.recurringExpenseSummaryForm.value.fromDate,
      toDate: this.recurringExpenseSummaryForm.value.toDate,
    };
    this.spinnerService.show();

    this.memberStatementService
      .getRecurringExpenseSummary(searchFields)
      .subscribe(
        (response: RecurringExpenseSummarys) => {
          this.recurringExpenseSummary = response;
          this.listCount = this.recurringExpenseSummary.expenses.length;
          this.spinnerService.hide();
        },
        (err: any) => {
          console.log(err);
          this.spinnerService.hide();
        }
      );
    if (this.listCount === null) {
      this.listCount = 0;
    }
  }

  hasView() {
    let privilage = false;
    this.userDetails.menus.forEach((value) => {
      if (value.id === this.menuId) {
        if (value.privilegeView || value.privilegeModify) privilage = true;
      }
    });

    return privilage;
  }

  hasExport() {
    let privilage = false;

    this.userDetails.menus.forEach((value) => {
      if (value.id === this.menuId) {
        if (value.privilegeExport) privilage = true;
      }
    });

    return privilage;
  }

  exportToExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      this.table.nativeElement
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "RecurringExpenseSummary");

    XLSX.writeFile(wb, "RecurringExpenseSummary.xlsx");
  }

  displayFeedback(stype: SweetAlertType, stitle: string, stext: string) {
    Swal.fire({
      toast: true,
      type: stype,
      title: stitle,
      text: stext,
      showConfirmButton: false,
      position: "top",
      timer: 3000,
    });
  }
}
