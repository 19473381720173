import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { AuthGuard } from "./../app/auth/guards/auth.guards";

import { LoginComponent } from "./core/components/login/login.component";
import { LogoutComponent } from "./core/components/logout/logout.component";
import { HomeComponent } from "./core/components/home/home.component";
import { DashbaordComponent } from "./core/components/dashbaord/dashbaord.component";

import { CategoryListPageComponent } from "./masters/category-list-page/category-list-page.component";
import { CategoryPageComponent } from "./masters/category-page/category-page.component";
import { JoinMessListPageComponent } from "./transactions/join-mess-list-page/join-mess-list-page.component";
import { JoinMessPageComponent } from "./transactions/join-mess-page/join-mess-page.component";
import { LeaveMessListPageComponent } from "./transactions/leave-mess-list-page/leave-mess-list-page.component";
import { LeaveMessPageComponent } from "./transactions/leave-mess-page/leave-mess-page.component";
import { AdvanceReturnListPageComponent } from "./transactions/advance-return-list-page/advance-return-list-page.component";
import { AdvanceReturnPageComponent } from "./transactions/advance-return-page/advance-return-page.component";
import { AdjustmentListPageComponent } from "./transactions/adjustment-list-page/adjustment-list-page.component";
import { AdjustmentPageComponent } from "./transactions/adjustment-page/adjustment-page.component";
import { ReceiptListPageComponent } from "./transactions/receipt-list-page/receipt-list-page.component";
import { ReceiptPageComponent } from "./transactions/receipt-page/receipt-page.component";
import { PaymentListPageComponent } from "./transactions/payment-list-page/payment-list-page.component";
import { PaymentPageComponent } from "./transactions/payment-page/payment-page.component";
import { PurchaseListPageComponent } from "./transactions/purchase-list-page/purchase-list-page.component";
import { PurchasePageComponent } from "./transactions/purchase-page/purchase-page.component";
import { GenerateInvoiceListPageComponent } from "./transactions/generate-invoice-list-page/generate-invoice-list-page.component";
import { GenerateInvoicePageComponent } from "./transactions/generate-invoice-page/generate-invoice-page.component";
import { RecurringExpenseListPageComponent } from "./transactions/recurring-expense-list-page/recurring-expense-list-page.component";
import { RecurringExpensePageComponent } from "./transactions/recurring-expense-page/recurring-expense-page.component";
import { InvoiceListPageComponent } from "./transactions/invoice-list-page/invoice-list-page.component";
import { MemberListPageComponent } from "./masters/member-list-page/member-list-page.component";
import { MemberPageComponent } from "./masters/member-page/member-page.component";
import { ExpenseListPageComponent } from "./masters/expense-list-page/expense-list-page.component";
import { ExpensePageComponent } from "./masters/expense-page/expense-page.component";
import { SupplierListPageComponent } from "./masters/supplier-list-page/supplier-list-page.component";
import { SupplierPageComponent } from "./masters/supplier-page/supplier-page.component";
import { ActiveMembersComponent } from "./reports/active-members/active-members.component";
import { RoleListPageComponent } from "./system/role-list-page/role-list-page.component";
import { RolePageComponent } from "./system/role-page/role-page.component";
import { UserListPageComponent } from "./system/user-list-page/user-list-page.component";
import { UserPageComponent } from "./system/user-page/user-page.component";
import { SettingsPageComponent } from "./system/settings-page/settings-page.component";
import { AdvanceRegisterComponent } from "./reports/advance-register/advance-register.component";
import { MemberStatementComponent } from "./reports/member-statement/member-statement.component";
import { MemberOutstandingComponent } from "./reports/member-outstanding/member-outstanding.component";
import { ReceiptSummaryComponent } from "./reports/receipt-summary/receipt-summary.component";
import { InvoiceRegisterComponent } from "./reports/invoice-register/invoice-register.component";
import { CollectionRegisterComponent } from "./reports/collection-register/collection-register.component";
import { PurchaseSummaryComponent } from "./reports/purchase-summary/purchase-summary.component";
import { RecurringExpenseSummaryComponent } from "./reports/recurring-expense-summary/recurring-expense-summary.component";
import { MonthlyStatementComponent } from "./reports/monthly-statement/monthly-statement.component";
import { AdvanceReturnSummaryComponent } from "./reports/advance-return-summary/advance-return-summary.component";
import { PaymentSummaryComponent } from "./reports/payment-summary/payment-summary.component";

const routes: Routes = [
  { path: "", redirectTo: "login", pathMatch: "full" },
  { path: "login", component: LoginComponent },
  { path: "logout", component: LogoutComponent },
  {
    path: "home",
    component: HomeComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "dashboard",
        component: DashbaordComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "category",
        component: CategoryListPageComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "category/:id",
        component: CategoryPageComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "member",
        component: MemberListPageComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "member/:id",
        component: MemberPageComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "expense",
        component: ExpenseListPageComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "expense/:id",
        component: ExpensePageComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "supplier",
        component: SupplierListPageComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "supplier/:id",
        component: SupplierPageComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "join-mess",
        component: JoinMessListPageComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "join-mess/:id",
        component: JoinMessPageComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "leave-mess",
        component: LeaveMessListPageComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "leave-mess/:id",
        component: LeaveMessPageComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "advance-return",
        component: AdvanceReturnListPageComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "advance-return/:id",
        component: AdvanceReturnPageComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "adjustment",
        component: AdjustmentListPageComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "adjustment/:id",
        component: AdjustmentPageComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "receipt",
        component: ReceiptListPageComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "receipt/:id",
        component: ReceiptPageComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "payment",
        component: PaymentListPageComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "payment/:id",
        component: PaymentPageComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "purchase",
        component: PurchaseListPageComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "purchase/:id",
        component: PurchasePageComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "recurring-expense",
        component: RecurringExpenseListPageComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "recurring-expense/:id",
        component: RecurringExpensePageComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "generate-invoice",
        component: GenerateInvoiceListPageComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "generate-invoice/:id",
        component: GenerateInvoicePageComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "invoice",
        component: InvoiceListPageComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "active-members",
        component: ActiveMembersComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "advance-register",
        component: AdvanceRegisterComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "member-statement",
        component: MemberStatementComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "member-outstanding",
        component: MemberOutstandingComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "receipt-summary",
        component: ReceiptSummaryComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "advance-return-summary",
        component: AdvanceReturnSummaryComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "payment-summary",
        component: PaymentSummaryComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "invoice-register",
        component: InvoiceRegisterComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "invoice-register/:id",
        component: InvoiceRegisterComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "collection-register",
        component: CollectionRegisterComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "collection-register/:id",
        component: CollectionRegisterComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "purchase-summary",
        component: PurchaseSummaryComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "recurring-expense-summary",
        component: RecurringExpenseSummaryComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "monthly-statement",
        component: MonthlyStatementComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "monthly-statement/:id",
        component: MonthlyStatementComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "roles",
        component: RoleListPageComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "role/:id",
        component: RolePageComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "users",
        component: UserListPageComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "user/:id",
        component: UserPageComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
      {
        path: "settings",
        component: SettingsPageComponent,
        canActivate: [AuthGuard],
        outlet: "pages",
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
