import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormControl, FormGroup } from "@angular/forms";
import { Observable, concat, of, Subject } from "rxjs";
import {
  debounceTime,
  map,
  distinctUntilChanged,
  tap,
  switchMap,
  catchError,
} from "rxjs/operators";
import { NgbDateStruct, NgbCalendar } from "@ng-bootstrap/ng-bootstrap";
import { NgbStringAdapterService } from "src/app/shared/services/ngb-string-adapter.service";
import {
  HttpResponse,
  HttpResult,
} from "src/app/core/models/utils/http-response";
import Swal, { SweetAlertType } from "sweetalert2";

import { UserDetails } from "src/app/core/models/system/user-details";

import { Payment } from "src/app/core/models/transactions/payment";
import { PaymentService } from "src/app/core/services/transactions/payment.service";
import { Member } from "src/app/core/models/masters/member";
import { MemberList } from "src/app/core/models/masters/member-list";
import { MemberService } from "src/app/core/services/masters/member.service";
import { JoinMessService } from "src/app/core/services/transactions/join-mess.service";

@Component({
  selector: "app-payment-page",
  templateUrl: "./payment-page.component.html",
  styleUrls: ["./payment-page.component.css"],
})
export class PaymentPageComponent implements OnInit {
  members$: Member[];
  paymentDate: NgbDateStruct;

  userDetails: UserDetails;
  menuId: number;

  id: number;
  member: number;
  memberName: string;

  paymentForm = new FormGroup({
    id: new FormControl(0),
    paymentNum: new FormControl(""),
    paymentDate: new FormControl(new Date()),
    member: new FormControl({ id: 0, name: "" }),
    memberName: new FormControl(""),
    balAmt: new FormControl(0),
    amount: new FormControl(0),
    notes: new FormControl(""),
  });

  memberLoading = false;
  member$: Observable<MemberList[]>;
  memberInput$ = new Subject<string>();
  selectedMember: any;

  constructor(
    private paymentService: PaymentService,
    private joinMessService: JoinMessService,
    private memberService: MemberService,
    private ngbDateAdapter: NgbStringAdapterService,
    private calendar: NgbCalendar,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.paymentDate = this.calendar.getToday();
    this.loadMembers();
    this.userDetails = JSON.parse(localStorage.getItem("userDetails"));
    this.menuId = 26000;

    this.id = 0;

    this.route.paramMap.subscribe((params) => {
      const expID = +params.get("id");
      if (expID) {
        this.getPayment(expID);
      }
    });
    this.paymentDate = this.calendar.getToday();
  }

  cancelPayment() {
    this.router.navigate(["./", { outlets: { pages: ["payment"] } }], {
      relativeTo: this.route.parent,
    });
  }

  getPayment(id: number) {
    this.paymentService.getPayment(id).subscribe(
      (payment: Payment) => this.editPayment(payment),
      (err: any) => {
        this.displayFeedback(
          "error",
          "Error!",
          "Something went wrong. Try again!"
        );
        this.router.navigate(["./", { outlets: { pages: ["payment"] } }], {
          relativeTo: this.route.parent,
        });
      }
    );
  }

  editPayment(payment: Payment) {
    this.paymentForm.patchValue({
      id: payment.id,
      paymentNum: payment.paymentNum,
      paymentDate: payment.paymentDate,
      member: { id: payment.member, name: payment.memberName },
      memberName: payment.memberName,
      balAmt: payment.balAmt,
      amount: payment.amount,
      notes: payment.notes,
    });
    this.id = payment.id;
    this.member = payment.member;
    this.memberName = payment.memberName;

    this.paymentDate = this.ngbDateAdapter.fromModel(
      new Date(payment.paymentDate)
    );
  }

  savePayment(reset: boolean) {
    const payment: Payment = {
      id: this.paymentForm.value.id,
      paymentNum: this.paymentForm.value.paymentNum,
      paymentDate: this.paymentForm.value.paymentDate,
      member: this.paymentForm.value.member.id,
      memberName: this.paymentForm.value.member.memberName,
      balAmt: this.paymentForm.value.balAmt,
      amount: this.paymentForm.value.amount,
      notes: this.paymentForm.value.notes,
    };

    if (payment.id < 1) {
      this.paymentService.add(payment).subscribe(
        (response: HttpResponse) => {
          if (response.result === HttpResult.Success) {
            this.displayFeedback("success", "Saved Successfully!", "");
            if (reset) {
              this.resetPayment();
            } else {
              this.router.navigate(
                ["./", { outlets: { pages: ["payment"] } }],
                { relativeTo: this.route.parent }
              );
            }
          } else {
            this.displayFeedback(
              "warning",
              response.result + "!",
              response.message
            );
          }
        },
        (error) => {
          this.displayFeedback("error", "Error!", error);
        }
      );
    } else {
      this.paymentService.modify(payment).subscribe(
        (response: HttpResponse) => {
          if (response.result === HttpResult.Success) {
            this.displayFeedback("success", "Saved Successfully!", "");
            if (reset) {
              this.resetPayment();
            } else {
              this.router.navigate(
                ["./", { outlets: { pages: ["payment"] } }],
                { relativeTo: this.route.parent }
              );
            }
          } else {
            this.displayFeedback(
              "warning",
              response.result + "!",
              response.message
            );
          }
        },
        (error) => {
          this.displayFeedback("error", "Error!", error);
        }
      );
    }
  }

  getMemberDetails() {
    if (this.paymentForm.value.member != null) {
      this.memberService
        .getMemberPaymentBalance(
          this.paymentForm.value.member.id,
          this.paymentDate.year +
            "-" +
            this.paymentDate.month +
            "-" +
            this.paymentDate.day
        )
        .subscribe(
          (response) => {
            this.paymentForm.patchValue({
              balAmt: response.memberBalance.totalBalAmt,
            });
          },
          (err: any) => console.log(err)
        );
    }
  }

  resetPayment() {
    this.paymentForm.reset({
      id: 0,
      paymentNum: "",
      paymentDate: new Date(),
      member: { id: 0, name: "" },
      memberName: "",
      balAmt: 0,
      amount: 0,
      notes: "",
    });
  }

  private loadMembers() {
    this.member$ = concat(
      of([]),
      this.memberInput$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => (this.memberLoading = true)),
        switchMap((term) =>
          this.memberService.getMembersList(term).pipe(
            catchError(() => of([])),
            tap(() => (this.memberLoading = false))
          )
        )
      )
    );
  }

  hasAdd() {
    let privilage = false;

    this.userDetails.menus.forEach((value) => {
      if (value.id === this.menuId) {
        if (value.privilegeAdd && this.paymentForm.value.id < 1)
          privilage = true;
        else if (value.privilegeModify && this.paymentForm.value.id > 0)
          privilage = true;
      }
    });

    return privilage;
  }

  displayFeedback(stype: SweetAlertType, stitle: string, stext: string) {
    Swal.fire({
      toast: true,
      type: stype,
      title: stitle,
      text: stext,
      showConfirmButton: false,
      position: "top",
      timer: 3000,
    });
  }

  displayHTMLFeedback(stype: SweetAlertType, stitle: string, shtml: string) {
    Swal.fire({
      type: stype,
      title: stitle,
      html: shtml,
      showConfirmButton: true,
    });
  }
}
