import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormControl, FormGroup } from "@angular/forms";
import { Observable, concat, of, Subject } from "rxjs";
import {
  debounceTime,
  map,
  distinctUntilChanged,
  tap,
  switchMap,
  catchError,
} from "rxjs/operators";
import { NgbDateStruct, NgbCalendar } from "@ng-bootstrap/ng-bootstrap";
import { NgbStringAdapterService } from "src/app/shared/services/ngb-string-adapter.service";
import {
  HttpResponse,
  HttpResult,
} from "src/app/core/models/utils/http-response";
import Swal, { SweetAlertType } from "sweetalert2";

import { UserDetails } from "src/app/core/models/system/user-details";

import { LeaveMess } from "src/app/core/models/transactions/leave-mess";
import { LeaveMessService } from "src/app/core/services/transactions/leave-mess.service";
import { Member } from "src/app/core/models/masters/member";
import { MemberList } from "src/app/core/models/masters/member-list";
import { MemberService } from "src/app/core/services/masters/member.service";
import { JoinMessService } from "src/app/core/services/transactions/join-mess.service";
import { JoinMess } from "src/app/core/models/transactions/join-mess";

@Component({
  selector: "app-leave-mess-page",
  templateUrl: "./leave-mess-page.component.html",
  styleUrls: ["./leave-mess-page.component.css"],
})
export class LeaveMessPageComponent implements OnInit {
  members$: Member[];
  joinDate: NgbDateStruct;
  leaveDate: NgbDateStruct;

  userDetails: UserDetails;
  menuId: number;

  id: number;
  member: number;
  memberName: string;

  leaveMessForm = new FormGroup({
    id: new FormControl(0),
    member: new FormControl({ id: 0, name: "" }),
    memberName: new FormControl(""),
    joinDate: new FormControl(new Date()),
    leaveDate: new FormControl(new Date()),
    hasAdvanceReturn: new FormControl(false),
    advanceReturnAmount: new FormControl(0),
    notes: new FormControl(""),
  });

  memberLoading = false;
  member$: Observable<MemberList[]>;
  memberInput$ = new Subject<string>();
  selectedMember: any;

  constructor(
    private leaveMessService: LeaveMessService,
    private joinMessService: JoinMessService,
    private memberService: MemberService,
    private ngbDateAdapter: NgbStringAdapterService,
    private calendar: NgbCalendar,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.joinDate = this.calendar.getToday();
    this.leaveDate = this.calendar.getToday();
    this.loadMembers();
    this.userDetails = JSON.parse(localStorage.getItem("userDetails"));
    this.menuId = 22000;

    this.id = 0;

    this.route.paramMap.subscribe((params) => {
      const expID = +params.get("id");
      if (expID) {
        this.getLeaveMess(expID);
      }
    });
    this.joinDate = this.calendar.getToday();
  }

  cancelLeaveMess() {
    this.router.navigate(["./", { outlets: { pages: ["leave-mess"] } }], {
      relativeTo: this.route.parent,
    });
  }

  getLeaveMess(id: number) {
    this.leaveMessService.getLeaveMess(id).subscribe(
      (leaveMess: LeaveMess) => this.editLeaveMess(leaveMess),
      (err: any) => {
        this.displayFeedback(
          "error",
          "Error!",
          "Something went wrong. Try again!"
        );
        this.router.navigate(["./", { outlets: { pages: ["leave-mess"] } }], {
          relativeTo: this.route.parent,
        });
      }
    );
  }

  editLeaveMess(leaveMess: LeaveMess) {
    this.leaveMessForm.patchValue({
      id: leaveMess.id,
      member: { id: leaveMess.member, name: leaveMess.memberName },
      memberName: leaveMess.memberName,
      joinDate: leaveMess.joinDate,
      leaveDate: leaveMess.leaveDate,
      hasAdvanceReturn: leaveMess.hasAdvanceReturn,
      advanceReturnAmount: leaveMess.advanceReturnAmount,
      notes: leaveMess.notes,
    });
    this.id = leaveMess.id;
    this.member = leaveMess.member;
    this.memberName = leaveMess.memberName;

    this.joinDate = this.ngbDateAdapter.fromModel(new Date(leaveMess.joinDate));
    this.leaveDate = this.ngbDateAdapter.fromModel(
      new Date(leaveMess.leaveDate)
    );
  }

  saveLeaveMess(reset: boolean) {
    const leaveMess: LeaveMess = {
      id: this.leaveMessForm.value.id,
      member: this.leaveMessForm.value.member.id,
      memberName: this.leaveMessForm.value.member.memberName,
      joinDate: this.leaveMessForm.value.joinDate,
      leaveDate: this.leaveMessForm.value.leaveDate,
      hasAdvanceReturn: this.leaveMessForm.value.hasAdvanceReturn,
      advanceReturnAmount: this.leaveMessForm.value.hasAdvanceReturn
        ? this.leaveMessForm.value.advanceReturnAmount
        : 0,
      notes: this.leaveMessForm.value.notes,
    };

    if (leaveMess.id < 1) {
      this.leaveMessService.add(leaveMess).subscribe(
        (response: HttpResponse) => {
          if (response.result === HttpResult.Success) {
            this.displayFeedback("success", "Saved Successfully!", "");
            if (reset) {
              this.resetLeaveMess();
            } else {
              this.router.navigate(
                ["./", { outlets: { pages: ["leave-mess"] } }],
                { relativeTo: this.route.parent }
              );
            }
          } else {
            this.displayFeedback(
              "warning",
              response.result + "!",
              response.message
            );
          }
        },
        (error) => {
          this.displayFeedback("error", "Error!", error);
        }
      );
    } else {
      this.leaveMessService.modify(leaveMess).subscribe(
        (response: HttpResponse) => {
          if (response.result === HttpResult.Success) {
            this.displayFeedback("success", "Saved Successfully!", "");
            if (reset) {
              this.resetLeaveMess();
            } else {
              this.router.navigate(
                ["./", { outlets: { pages: ["leave-mess"] } }],
                { relativeTo: this.route.parent }
              );
            }
          } else {
            this.displayFeedback(
              "warning",
              response.result + "!",
              response.message
            );
          }
        },
        (error) => {
          this.displayFeedback("error", "Error!", error);
        }
      );
    }
  }

  getMemberDetails() {
    if (this.leaveMessForm.value.member != null) {
      this.joinMessService
        .getJoinMessForLeave(this.leaveMessForm.value.member.id)
        .subscribe(
          (joinMess: JoinMess) => {
            this.setLeave(joinMess);
          },
          (err: any) => console.log(err)
        );
    }
  }

  setLeave(joinMess: JoinMess) {
    if (joinMess != null) {
      this.leaveMessForm.patchValue({
        id: joinMess.id,
        joinDate: joinMess.joinDate,
        advanceReturnAmount: joinMess.advanceAmount,
      });
    }
  }

  resetLeaveMess() {
    this.leaveMessForm.reset({
      id: 0,
      member: { id: 0, name: "" },
      memberName: "",
      joinDate: new Date(),
      leaveDate: new Date(),
      hasAdvanceReturn: false,
      advanceReturnAmount: 0,
      notes: "",
    });
  }

  private loadMembers() {
    this.member$ = concat(
      of([]),
      this.memberInput$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => (this.memberLoading = true)),
        switchMap((term) =>
          this.memberService.getMembersListInMess(term).pipe(
            catchError(() => of([])),
            tap(() => (this.memberLoading = false))
          )
        )
      )
    );
  }

  hasAdd() {
    let privilage = false;

    this.userDetails.menus.forEach((value) => {
      if (value.id === this.menuId) {
        if (value.privilegeAdd && this.leaveMessForm.value.id < 1)
          privilage = true;
        else if (value.privilegeModify && this.leaveMessForm.value.id > 0)
          privilage = true;
      }
    });

    return privilage;
  }

  displayFeedback(stype: SweetAlertType, stitle: string, stext: string) {
    Swal.fire({
      toast: true,
      type: stype,
      title: stitle,
      text: stext,
      showConfirmButton: false,
      position: "top",
      timer: 3000,
    });
  }

  displayHTMLFeedback(stype: SweetAlertType, stitle: string, shtml: string) {
    Swal.fire({
      type: stype,
      title: stitle,
      html: shtml,
      showConfirmButton: true,
    });
  }
}
