import { Observable, throwError } from "rxjs";
import { catchError, retry } from "rxjs/operators";
import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpParams,
} from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { HttpResponse } from "../../models/utils/http-response";
import { Purchase } from "../../models/transactions/purchase";

@Injectable({
  providedIn: "root",
})
export class PurchaseService {
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };

  constructor(private http: HttpClient) {}

  getPurchases(): Observable<Purchase[]> {
    return this.http.get<Purchase[]>(environment.root + "/mess/purchase");
  }

  getPurchase(id: number): Observable<Purchase> {
    return this.http.get<Purchase>(environment.root + "/mess/purchase/" + id);
  }

  add(purchase: Purchase): Observable<HttpResponse> {
    return this.http
      .post<HttpResponse>(
        environment.root + "/mess/purchase/add",
        purchase,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  modify(purchase: Purchase): Observable<HttpResponse> {
    return this.http
      .post<HttpResponse>(
        environment.root + "/mess/purchase/modify",
        purchase,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  delete(id: number): Observable<HttpResponse> {
    return this.http
      .post<HttpResponse>(
        environment.root + "/mess/purchase/delete/" + id,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  getPurchaseBySearch(page, pageSize, searchBy, searchText) {
    const params = new HttpParams()
      .set("page", page.toString())
      .set("pageSize", pageSize.toString())
      .set("searchBy", searchBy.toString())
      .set("searchText", searchText.toString());

    return this.http.get<{
      totalItems: number;
      listItemFrom: number;
      listItemTo: number;
      purchases: Purchase[];
    }>(environment.root + "/mess/purchase/getallpurchasebysearch", {
      params,
    });
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error("An error occurred:", error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    return throwError("Something bad happened; please try again later.");
  }
}
