import { Observable, throwError } from "rxjs";
import { catchError, retry } from "rxjs/operators";
import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpParams,
} from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { HttpResponse } from "../../models/utils/http-response";
import { Adjustment } from "../../models/transactions/adjustment";

@Injectable({
  providedIn: "root",
})
export class AdjustmentService {
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };

  constructor(private http: HttpClient) {}

  getAdjustments(): Observable<Adjustment[]> {
    return this.http.get<Adjustment[]>(environment.root + "/mess/adjustment");
  }

  getAdjustment(id: number): Observable<Adjustment> {
    return this.http.get<Adjustment>(
      environment.root + "/mess/adjustment/" + id
    );
  }

  add(adjustment: Adjustment): Observable<HttpResponse> {
    return this.http
      .post<HttpResponse>(
        environment.root + "/mess/adjustment/add",
        adjustment,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  modify(adjustment: Adjustment): Observable<HttpResponse> {
    return this.http
      .post<HttpResponse>(
        environment.root + "/mess/adjustment/modify",
        adjustment,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  delete(id: number): Observable<HttpResponse> {
    return this.http
      .post<HttpResponse>(
        environment.root + "/mess/adjustment/delete/" + id,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  getAdjustmentBySearch(page, pageSize, searchBy, searchText) {
    const params = new HttpParams()
      .set("page", page.toString())
      .set("pageSize", pageSize.toString())
      .set("searchBy", searchBy.toString())
      .set("searchText", searchText.toString());

    return this.http.get<{
      totalItems: number;
      listItemFrom: number;
      listItemTo: number;
      adjustments: Adjustment[];
    }>(environment.root + "/mess/adjustment/getalladjustmentbysearch", {
      params,
    });
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error("An error occurred:", error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    return throwError("Something bad happened; please try again later.");
  }
}
