import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { SweetAlert2Module } from "@sweetalert2/ngx-sweetalert2";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerModule } from "ngx-spinner";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { CategoryPageComponent } from "./category-page/category-page.component";
import { CategoryListPageComponent } from "./category-list-page/category-list-page.component";
import { MemberPageComponent } from "./member-page/member-page.component";
import { MemberListPageComponent } from "./member-list-page/member-list-page.component";
import { ExpenseListPageComponent } from "./expense-list-page/expense-list-page.component";
import { SupplierListPageComponent } from "./supplier-list-page/supplier-list-page.component";
import { ExpensePageComponent } from "./expense-page/expense-page.component";
import { SupplierPageComponent } from "./supplier-page/supplier-page.component";
import { NgSelectModule } from "@ng-select/ng-select";

@NgModule({
  declarations: [
    CategoryPageComponent,
    CategoryListPageComponent,
    MemberPageComponent,
    MemberListPageComponent,
    ExpenseListPageComponent,
    SupplierListPageComponent,
    ExpensePageComponent,
    SupplierPageComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SweetAlert2Module,
    NgbModule.forRoot(),
    NgxSpinnerModule,
    BrowserAnimationsModule,
    NgSelectModule,
  ],
  exports: [],
})
export class MastersModule {}
