import { Component, OnInit } from "@angular/core";
import { UserDetails } from "src/app/core/models/system/user-details";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.css"],
})
export class NavbarComponent implements OnInit {
  userDetails: UserDetails;

  constructor() {}

  ngOnInit() {}

  hasAccess(menuId: number) {
    this.userDetails = JSON.parse(localStorage.getItem("userDetails"));
    let exist = false;
    /*for (const menus of this.userDetails.menus) {
      if (menus.id === menu) {
        exist = true;
      }
    }*/
    this.userDetails.menus.forEach((value) => {
      if (value.id === menuId) {
        exist = true;
      }
    });
    if (exist) {
      return true;
    } else {
      return false;
    }
  }
}
