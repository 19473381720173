import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormControl, FormGroup } from "@angular/forms";
import { Observable, concat, of, Subject } from "rxjs";
import {
  debounceTime,
  map,
  distinctUntilChanged,
  tap,
  switchMap,
  catchError,
} from "rxjs/operators";
import { NgbDateStruct, NgbCalendar } from "@ng-bootstrap/ng-bootstrap";
import { NgbStringAdapterService } from "src/app/shared/services/ngb-string-adapter.service";
import {
  HttpResponse,
  HttpResult,
} from "src/app/core/models/utils/http-response";
import Swal, { SweetAlertType } from "sweetalert2";

import { UserDetails } from "src/app/core/models/system/user-details";

import { Receipt } from "src/app/core/models/transactions/receipt";
import { ReceiptService } from "src/app/core/services/transactions/receipt.service";
import { Member } from "src/app/core/models/masters/member";
import { MemberList } from "src/app/core/models/masters/member-list";
import { MemberService } from "src/app/core/services/masters/member.service";
import { JoinMessService } from "src/app/core/services/transactions/join-mess.service";

@Component({
  selector: "app-receipt-page",
  templateUrl: "./receipt-page.component.html",
  styleUrls: ["./receipt-page.component.css"],
})
export class ReceiptPageComponent implements OnInit {
  members$: Member[];
  receiptDate: NgbDateStruct;

  userDetails: UserDetails;
  menuId: number;

  id: number;
  member: number;
  memberName: string;

  receiptForm = new FormGroup({
    id: new FormControl(0),
    receiptNum: new FormControl(""),
    receiptDate: new FormControl(new Date()),
    member: new FormControl({ id: 0, name: "" }),
    memberName: new FormControl(""),
    prevBalAmt: new FormControl(0),
    currentBalAmt: new FormControl(0),
    currentCollectAmt: new FormControl(0),
    totalBalAmt: new FormControl(0),
    amount: new FormControl(0),
    notes: new FormControl(""),
  });

  memberLoading = false;
  member$: Observable<MemberList[]>;
  memberInput$ = new Subject<string>();
  selectedMember: any;

  constructor(
    private receiptService: ReceiptService,
    private joinMessService: JoinMessService,
    private memberService: MemberService,
    private ngbDateAdapter: NgbStringAdapterService,
    private calendar: NgbCalendar,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.receiptDate = this.calendar.getToday();
    this.loadMembers();
    this.userDetails = JSON.parse(localStorage.getItem("userDetails"));
    this.menuId = 25000;

    this.id = 0;

    this.route.paramMap.subscribe((params) => {
      const expID = +params.get("id");
      if (expID) {
        this.getReceipt(expID);
      }
    });
    this.receiptDate = this.calendar.getToday();
  }

  cancelReceipt() {
    this.router.navigate(["./", { outlets: { pages: ["receipt"] } }], {
      relativeTo: this.route.parent,
    });
  }

  getReceipt(id: number) {
    this.receiptService.getReceipt(id).subscribe(
      (receipt: Receipt) => this.editReceipt(receipt),
      (err: any) => {
        this.displayFeedback(
          "error",
          "Error!",
          "Something went wrong. Try again!"
        );
        this.router.navigate(["./", { outlets: { pages: ["receipt"] } }], {
          relativeTo: this.route.parent,
        });
      }
    );
  }

  editReceipt(receipt: Receipt) {
    this.receiptForm.patchValue({
      id: receipt.id,
      receiptNum: receipt.receiptNum,
      receiptDate: receipt.receiptDate,
      member: { id: receipt.member, name: receipt.memberName },
      memberName: receipt.memberName,
      prevBalAmt: receipt.prevBalAmt,
      currentBalAmt: receipt.currentBalAmt,
      currentCollectAmt: receipt.currentCollectAmt,
      totalBalAmt: receipt.totalBalAmt,
      amount: receipt.amount,
      notes: receipt.notes,
    });
    this.id = receipt.id;
    this.member = receipt.member;
    this.memberName = receipt.memberName;

    this.receiptDate = this.ngbDateAdapter.fromModel(
      new Date(receipt.receiptDate)
    );
  }

  saveReceipt(reset: boolean) {
    const receipt: Receipt = {
      id: this.receiptForm.value.id,
      receiptNum: this.receiptForm.value.receiptNum,
      receiptDate: this.receiptForm.value.receiptDate,
      member: this.receiptForm.value.member.id,
      memberName: this.receiptForm.value.member.memberName,
      prevBalAmt: this.receiptForm.value.prevBalAmt,
      currentBalAmt: this.receiptForm.value.currentBalAmt,
      currentCollectAmt: this.receiptForm.value.currentCollectAmt,
      totalBalAmt: this.receiptForm.value.totalBalAmt,
      amount: this.receiptForm.value.amount,
      notes: this.receiptForm.value.notes,
    };

    if (receipt.id < 1) {
      this.receiptService.add(receipt).subscribe(
        (response: HttpResponse) => {
          if (response.result === HttpResult.Success) {
            this.displayFeedback("success", "Saved Successfully!", "");
            if (reset) {
              this.resetReceipt();
            } else {
              this.router.navigate(
                ["./", { outlets: { pages: ["receipt"] } }],
                { relativeTo: this.route.parent }
              );
            }
          } else {
            this.displayFeedback(
              "warning",
              response.result + "!",
              response.message
            );
          }
        },
        (error) => {
          this.displayFeedback("error", "Error!", error);
        }
      );
    } else {
      this.receiptService.modify(receipt).subscribe(
        (response: HttpResponse) => {
          if (response.result === HttpResult.Success) {
            this.displayFeedback("success", "Saved Successfully!", "");
            if (reset) {
              this.resetReceipt();
            } else {
              this.router.navigate(
                ["./", { outlets: { pages: ["receipt"] } }],
                { relativeTo: this.route.parent }
              );
            }
          } else {
            this.displayFeedback(
              "warning",
              response.result + "!",
              response.message
            );
          }
        },
        (error) => {
          this.displayFeedback("error", "Error!", error);
        }
      );
    }
  }

  getMemberDetails() {
    if (this.receiptForm.value.member != null) {
      this.memberService
        .getMemberBalance(
          this.receiptForm.value.member.id,
          this.receiptDate.year +
            "-" +
            this.receiptDate.month +
            "-" +
            this.receiptDate.day
        )
        .subscribe(
          (response) => {
            console.log(response);
            this.receiptForm.patchValue({
              prevBalAmt: response.memberBalance.prevBalAmt,
              currentBalAmt: response.memberBalance.currentBalAmt,
              currentCollectAmt: response.memberBalance.currentCollectAmt,
              totalBalAmt: response.memberBalance.totalBalAmt,
            });
            console.log(this.receiptForm.value);
          },
          (err: any) => console.log(err)
        );
    }
  }

  resetReceipt() {
    this.receiptForm.reset({
      id: 0,
      receiptNum: "",
      receiptDate: new Date(),
      member: { id: 0, name: "" },
      memberName: "",
      prevBalAmt: 0,
      currentBalAmt: 0,
      currentCollectAmt: 0,
      totalBalAmt: 0,
      amount: 0,
      notes: "",
    });
  }

  private loadMembers() {
    this.member$ = concat(
      of([]),
      this.memberInput$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => (this.memberLoading = true)),
        switchMap((term) =>
          this.memberService.getMembersList(term).pipe(
            catchError(() => of([])),
            tap(() => (this.memberLoading = false))
          )
        )
      )
    );
  }

  hasAdd() {
    let privilage = false;

    this.userDetails.menus.forEach((value) => {
      if (value.id === this.menuId) {
        if (value.privilegeAdd && this.receiptForm.value.id < 1)
          privilage = true;
        else if (value.privilegeModify && this.receiptForm.value.id > 0)
          privilage = true;
      }
    });

    return privilage;
  }

  displayFeedback(stype: SweetAlertType, stitle: string, stext: string) {
    Swal.fire({
      toast: true,
      type: stype,
      title: stitle,
      text: stext,
      showConfirmButton: false,
      position: "top",
      timer: 3000,
    });
  }

  displayHTMLFeedback(stype: SweetAlertType, stitle: string, shtml: string) {
    Swal.fire({
      type: stype,
      title: stitle,
      html: shtml,
      showConfirmButton: true,
    });
  }
}
