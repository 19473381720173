export class HttpResponse {
    result: string;
    message: string;
    id: number;
}

export enum HttpResult {
    Success = 'SUCCESS',
    Invalid = 'INVALID',
    Error = 'ERROR',
    Unknown = 'UNKNOWN'
}
