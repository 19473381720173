import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appDecimal]'
})
export class DecimalDirective {

  // Allow decimal numbers and negative values
 private regex: RegExp = new RegExp(/^\d*\.?\d{0,3}$/g);
 // Allow key codes for special events. Reflect :
 // Backspace, tab, end, home
 private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];

 constructor(private el: ElementRef) {
 }
 @HostListener('keydown', ['$event'])
 onKeyDown(event: KeyboardEvent) {
  // console.log(this.el.nativeElement.value);
   // Allow Backspace, tab, end, and home keys
   if (this.specialKeys.indexOf(event.key) !== -1) {
     return;
   }
   const current: string = this.el.nativeElement.value;
   const next: string = current.concat(event.key);
   if (next && !String(next).match(this.regex)) {
     event.preventDefault();
   }
 }
}
