import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import Swal, { SweetAlertType } from 'sweetalert2';

import { UsersService } from './../../services/system/users.service';
import { HttpResponse, HttpResult } from '../../models/utils/http-response';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  resetPasswordForm = new FormGroup({
    UserId: new FormControl(''),
    Email: new FormControl(''),
    Password: new FormControl(''),
    CPassword: new FormControl(''),
    SKey1: new FormControl(''),
    SKey2: new FormControl(''),
    SKey3: new FormControl('')
  });

  constructor(private userService: UsersService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {

    const userId = this.route.snapshot.queryParamMap.get('userId');
    const email = this.route.snapshot.queryParamMap.get('email');
    const sKey1 = this.route.snapshot.queryParamMap.get('sKey1');
    const sKey2 = this.route.snapshot.queryParamMap.get('sKey2');
    const sKey3 = this.route.snapshot.queryParamMap.get('sKey3');

    this.resetPasswordForm.patchValue ({
      UserId: userId,
      Email: email,
      SKey1: sKey1,
      SKey2: sKey2,
      SKey3: sKey3
    });
  }

  onSubmit() {
    this.userService.resetPassword(this.resetPasswordForm.value).subscribe(
      (res: HttpResponse) => {
        if (res.result === HttpResult.Success) {
          this.displayHTMLFeedback('success', 'Password updated successfully!', '');
          this.router.navigateByUrl('login');
        } else {
          this.displayHTMLFeedback('warning', res.result + '!', res.message);
        }
      },
      err => {
        if (err.status === 400) {
          this.displayFeedback('warning', 'Incorrect username or password', 'Authentication failed.!');
        } else {
          this.displayFeedback('error', 'Error!', err);
        }
      }
    );
  }

  resetLogin() {
    this.resetPasswordForm.reset({
      Id: '',
      Email: '',
      Password: '',
      CPassword: ''
    });
  }

  displayFeedback(stype: SweetAlertType, stitle: string, stext: string) {
    Swal.fire({
      toast: true,
      type: stype,
      title: stitle,
      text: stext,
      showConfirmButton: false,
      position: 'top',
      timer: 3000
    });
  }

  displayHTMLFeedback(stype: SweetAlertType, stitle: string, shtml: string) {
    Swal.fire({
      type: stype,
      title: stitle,
      html: shtml,
      showConfirmButton: true
    });
  }
}
