import { Component, OnInit, ContentChild } from "@angular/core";
import { concat, Observable, of, Subject } from "rxjs";
import { NgbDateStruct, NgbCalendar } from "@ng-bootstrap/ng-bootstrap";
import Swal, { SweetAlertType } from "sweetalert2";
import * as XLSX from "xlsx";
import { ViewChild, ElementRef } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import {
  debounceTime,
  map,
  distinctUntilChanged,
  tap,
  switchMap,
  catchError,
} from "rxjs/operators";

import { UserDetails } from "src/app/core/models/system/user-details";
import { MemberOutstandings } from "src/app/core/models/reports/member-outstandings";
import { MemberOutstandingSearch } from "src/app/core/models/reports/member-outstanding-search";
import { ReportGeneratorService } from "src/app/core/services/reports/report-generator.services";
import { FormControl, FormGroup } from "@angular/forms";
import { MemberList } from "src/app/core/models/masters/member-list";
import { MemberService } from "src/app/core/services/masters/member.service";

@Component({
  selector: "app-member-outstanding",
  templateUrl: "./member-outstanding.component.html",
  styleUrls: ["./member-outstanding.component.css"],
})
export class MemberOutstandingComponent implements OnInit {
  @ViewChild("table", { static: true }) table: ElementRef;

  asonDate: NgbDateStruct;
  members$: MemberList[];

  member: number;
  memberName: string;

  userDetails: UserDetails;
  menuId: number;
  memberOutstandings: MemberOutstandings;
  listCount: any;

  memberOutstandingForm = new FormGroup({
    member: new FormControl({ id: 0, name: "" }),
    memberName: new FormControl(""),
    asonDate: new FormControl(new Date()),
    includeNegative: new FormControl(false),
  });

  memberLoading = false;
  member$: Observable<MemberList[]>;
  memberInput$ = new Subject<string>();
  selectedMember: any;

  constructor(
    private calendar: NgbCalendar,
    private memberOutstandingService: ReportGeneratorService,
    private memberService: MemberService,
    private spinnerService: NgxSpinnerService
  ) {
    this.memberOutstandings = { totalAmount: 0, outstandings: null };
  }

  ngOnInit() {
    this.asonDate = this.calendar.getToday();

    this.loadMembers();
    this.menuId = 31000;
    this.listCount = 0;
    this.userDetails = JSON.parse(localStorage.getItem("userDetails"));
  }

  resetFilters() {
    this.memberOutstandingForm.reset({
      member: { id: 0, name: "" },
      memberName: "",
      asonDate: new Date(),
      includeNegative: false,
    });
  }

  private loadMembers() {
    this.member$ = concat(
      of([]),
      this.memberInput$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => (this.memberLoading = true)),
        switchMap((term) =>
          this.memberService.getMembersList(term).pipe(
            catchError(() => of([])),
            tap(() => (this.memberLoading = false))
          )
        )
      )
    );
  }

  setMemberOutstandingList() {
    if (this.memberOutstandingForm.value.member === null) {
      this.memberOutstandingForm.value.member = { id: 0, name: "" };
    } else {
      if (this.memberOutstandingForm.value.member === 0) {
        this.memberOutstandingForm.value.member = { id: 0, name: "" };
      }
    }
    const searchFields: MemberOutstandingSearch = {
      member: this.memberOutstandingForm.value.member.id,
      asonDate: this.memberOutstandingForm.value.asonDate,
      includeNegative: this.memberOutstandingForm.value.includeNegative,
    };
    this.spinnerService.show();

    this.memberOutstandingService.getMemberOutstandings(searchFields).subscribe(
      (response: MemberOutstandings) => {
        this.memberOutstandings = response;
        this.listCount = this.memberOutstandings.outstandings.length;
        this.spinnerService.hide();
      },
      (err: any) => {
        console.log(err);
        this.spinnerService.hide();
      }
    );
    if (this.listCount === null) {
      this.listCount = 0;
    }
  }

  hasView() {
    let privilage = false;
    this.userDetails.menus.forEach((value) => {
      if (value.id === this.menuId) {
        if (value.privilegeView || value.privilegeModify) privilage = true;
      }
    });

    return privilage;
  }

  hasExport() {
    let privilage = false;

    this.userDetails.menus.forEach((value) => {
      if (value.id === this.menuId) {
        if (value.privilegeExport) privilage = true;
      }
    });

    return privilage;
  }

  exportToExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      this.table.nativeElement
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "MemberOutstandings");

    XLSX.writeFile(wb, "MemberOutstandings.xlsx");
  }

  displayFeedback(stype: SweetAlertType, stitle: string, stext: string) {
    Swal.fire({
      toast: true,
      type: stype,
      title: stitle,
      text: stext,
      showConfirmButton: false,
      position: "top",
      timer: 3000,
    });
  }
}
