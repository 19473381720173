import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerModule } from "ngx-spinner";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ActiveMembersComponent } from "./active-members/active-members.component";
import { CoreModule } from "../core/core.module";
import { AdvanceRegisterComponent } from "./advance-register/advance-register.component";
import { MemberStatementComponent } from "./member-statement/member-statement.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { MemberOutstandingComponent } from './member-outstanding/member-outstanding.component';
import { ReceiptSummaryComponent } from './receipt-summary/receipt-summary.component';
import { InvoiceRegisterComponent } from './invoice-register/invoice-register.component';
import { CollectionRegisterComponent } from './collection-register/collection-register.component';
import { PurchaseSummaryComponent } from './purchase-summary/purchase-summary.component';
import { RecurringExpenseSummaryComponent } from './recurring-expense-summary/recurring-expense-summary.component';
import { MonthlyStatementComponent } from './monthly-statement/monthly-statement.component';
import { PaymentSummaryComponent } from './payment-summary/payment-summary.component';
import { AdvanceReturnSummaryComponent } from './advance-return-summary/advance-return-summary.component';

@NgModule({
  declarations: [
    ActiveMembersComponent,
    AdvanceRegisterComponent,
    MemberStatementComponent,
    MemberOutstandingComponent,
    ReceiptSummaryComponent,
    InvoiceRegisterComponent,
    CollectionRegisterComponent,
    PurchaseSummaryComponent,
    RecurringExpenseSummaryComponent,
    MonthlyStatementComponent,
    PaymentSummaryComponent,
    AdvanceReturnSummaryComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    NgSelectModule,
    CoreModule,
  ],
  exports: [],
})
export class ReportsModule {}
