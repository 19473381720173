import { Observable, throwError } from "rxjs";
import { catchError, retry } from "rxjs/operators";
import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpParams,
} from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { Member } from "../../models/masters/Member";
import { HttpResponse } from "../../models/utils/http-response";
import { MemberList } from "../../models/masters/member-list";

@Injectable({
  providedIn: "root",
})
export class MemberService {
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };

  constructor(private http: HttpClient) {}

  getMembers(): Observable<Member[]> {
    return this.http.get<Member[]>(environment.root + "/mess/member");
  }

  getMember(id: number): Observable<Member> {
    return this.http.get<Member>(environment.root + "/mess/member/" + id);
  }

  getMembersList(term: string): Observable<MemberList[]> {
    const params = new HttpParams().set("filter", term);
    return this.http.get<MemberList[]>(
      environment.root + "/mess/member/getmemberslist/",
      { params }
    );
  }

  getMembersListInMess(term: string): Observable<MemberList[]> {
    const params = new HttpParams().set("filter", term);
    return this.http.get<MemberList[]>(
      environment.root + "/mess/member/getmembersinmesslist/",
      { params }
    );
  }

  getMembersListNotInMess(term: string): Observable<MemberList[]> {
    const params = new HttpParams().set("filter", term);
    return this.http.get<MemberList[]>(
      environment.root + "/mess/member/getmembersnotinmesslist/",
      { params }
    );
  }

  getMembersForAdvanceReturn(term: string): Observable<MemberList[]> {
    const params = new HttpParams().set("filter", term);
    return this.http.get<MemberList[]>(
      environment.root + "/mess/member/getmembersforadvancereturnlist/",
      { params }
    );
  }

  getMemberAdvanceInfo(member: any, refDate: any): Observable<any> {
    const params = new HttpParams()
      .set("member", member)
      .set("refDate", refDate);
    return this.http.get<any>(
      environment.root + "/mess/member/getmemberadvanceinfo/",
      { params }
    );
  }

  getMemberBalance(member, rdate): Observable<any> {
    const params = new HttpParams().set("member", member).set("refDate", rdate);
    return this.http.get<any>(
      environment.root + "/mess/member/getmemberbalanceinfo",
      { params: params }
    );
  }

  getMemberPaymentBalance(member, rdate): Observable<any> {
    const params = new HttpParams().set("member", member).set("refDate", rdate);
    return this.http.get<any>(
      environment.root + "/mess/member/getmemberpaymentbalanceinfo",
      { params: params }
    );
  }

  add(member: Member): Observable<HttpResponse> {
    console.log(member);
    return this.http
      .post<HttpResponse>(
        environment.root + "/mess/member/add",
        member,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  modify(member: Member): Observable<HttpResponse> {
    return this.http
      .post<HttpResponse>(
        environment.root + "/mess/member/modify",
        member,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  delete(id: number): Observable<HttpResponse> {
    return this.http
      .post<HttpResponse>(
        environment.root + "/mess/member/delete/" + id,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  getMemberBySearch(page, pageSize, searchBy, searchText) {
    const params = new HttpParams()
      .set("page", page.toString())
      .set("pageSize", pageSize.toString())
      .set("searchBy", searchBy.toString())
      .set("searchText", searchText.toString());

    return this.http.get<{
      totalItems: number;
      listItemFrom: number;
      listItemTo: number;
      members: Member[];
    }>(environment.root + "/mess/member/getallmemberbysearch", { params });
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error("An error occurred:", error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    return throwError("Something bad happened; please try again later.");
  }
}
