import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormControl, FormGroup } from "@angular/forms";
import { Observable } from "rxjs";
import { NgbDateStruct, NgbCalendar } from "@ng-bootstrap/ng-bootstrap";
import { NgbStringAdapterService } from "src/app/shared/services/ngb-string-adapter.service";
import {
  HttpResponse,
  HttpResult,
} from "src/app/core/models/utils/http-response";
import Swal, { SweetAlertType } from "sweetalert2";

import { UserDetails } from "src/app/core/models/system/user-details";

import { RecurringExpense } from "src/app/core/models/transactions/recurring-expense";
import { RecurringExpenseService } from "src/app/core/services/transactions/recurring-expense.service";
import { Expense } from "src/app/core/models/masters/expense";
import { ExpenseService } from "src/app/core/services/masters/expense.service";

@Component({
  selector: "app-recurring-expense-page",
  templateUrl: "./recurring-expense-page.component.html",
  styleUrls: ["./recurring-expense-page.component.css"],
})
export class RecurringExpensePageComponent implements OnInit {
  expenses$: Observable<Expense[]>;
  recurringExpenseDate: NgbDateStruct;

  userDetails: UserDetails;
  menuId: number;

  id: number;

  recurringExpenseForm = new FormGroup({
    id: new FormControl(0),
    recExpenseNum: new FormControl(""),
    recExpenseDate: new FormControl(new Date()),
    expense: new FormControl(0),
    expenseName: new FormControl(""),
    amount: new FormControl(0),
    balanceAmount: new FormControl(0),
    notes: new FormControl(""),
    processed: new FormControl(false),
  });

  constructor(
    private recurringExpenseService: RecurringExpenseService,
    private expenseService: ExpenseService,
    private ngbDateAdapter: NgbStringAdapterService,
    private calendar: NgbCalendar,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.recurringExpenseDate = this.calendar.getToday();
    this.userDetails = JSON.parse(localStorage.getItem("userDetails"));
    this.menuId = 29000;

    this.id = 0;
    this.expenses$ = this.expenseService.getExpenses();

    this.route.paramMap.subscribe((params) => {
      const expID = +params.get("id");
      if (expID) {
        this.getRecurringExpense(expID);
      }
    });
    this.recurringExpenseDate = this.calendar.getToday();
  }

  cancelRecurringExpense() {
    this.router.navigate(
      ["./", { outlets: { pages: ["recurring-expense"] } }],
      {
        relativeTo: this.route.parent,
      }
    );
  }

  getRecurringExpense(id: number) {
    this.recurringExpenseService.getRecurringExpense(id).subscribe(
      (recurringExpense: RecurringExpense) =>
        this.editRecurringExpense(recurringExpense),
      (err: any) => {
        this.displayFeedback(
          "error",
          "Error!",
          "Something went wrong. Try again!"
        );
        this.router.navigate(
          ["./", { outlets: { pages: ["recurring-expense"] } }],
          {
            relativeTo: this.route.parent,
          }
        );
      }
    );
  }

  editRecurringExpense(recurringExpense: RecurringExpense) {
    this.recurringExpenseForm.patchValue({
      id: recurringExpense.id,
      recExpenseNum: recurringExpense.recExpenseNum,
      recExpenseDate: recurringExpense.recExpenseDate,
      expense: recurringExpense.expense,
      expenseName: recurringExpense.expenseName,
      amount: recurringExpense.amount,
      balanceAmount: recurringExpense.balanceAmount,
      notes: recurringExpense.notes,
      processed: recurringExpense.processed,
    });
    this.id = recurringExpense.id;

    this.recurringExpenseDate = this.ngbDateAdapter.fromModel(
      new Date(recurringExpense.recExpenseDate)
    );
  }

  saveRecurringExpense(reset: boolean) {
    const recurringExpense: RecurringExpense = {
      id: this.recurringExpenseForm.value.id,
      recExpenseNum: this.recurringExpenseForm.value.recExpenseNum,
      recExpenseDate: this.recurringExpenseForm.value.recExpenseDate,
      expense: this.recurringExpenseForm.value.expense,
      expenseName: this.recurringExpenseForm.value.expenseName,
      amount: this.recurringExpenseForm.value.amount,
      balanceAmount: this.recurringExpenseForm.value.balanceAmount,
      notes: this.recurringExpenseForm.value.notes,
      processed: this.recurringExpenseForm.value.processed,
    };

    if (recurringExpense.id < 1) {
      this.recurringExpenseService.add(recurringExpense).subscribe(
        (response: HttpResponse) => {
          if (response.result === HttpResult.Success) {
            this.displayFeedback("success", "Saved Successfully!", "");
            if (reset) {
              this.resetRecurringExpense();
            } else {
              this.router.navigate(
                ["./", { outlets: { pages: ["recurring-expense"] } }],
                { relativeTo: this.route.parent }
              );
            }
          } else {
            this.displayFeedback(
              "warning",
              response.result + "!",
              response.message
            );
          }
        },
        (error) => {
          this.displayFeedback("error", "Error!", error);
        }
      );
    } else {
      this.recurringExpenseService.modify(recurringExpense).subscribe(
        (response: HttpResponse) => {
          if (response.result === HttpResult.Success) {
            this.displayFeedback("success", "Saved Successfully!", "");
            if (reset) {
              this.resetRecurringExpense();
            } else {
              this.router.navigate(
                ["./", { outlets: { pages: ["recurring-expense"] } }],
                { relativeTo: this.route.parent }
              );
            }
          } else {
            this.displayFeedback(
              "warning",
              response.result + "!",
              response.message
            );
          }
        },
        (error) => {
          this.displayFeedback("error", "Error!", error);
        }
      );
    }
  }

  resetRecurringExpense() {
    this.recurringExpenseForm.reset({
      id: 0,
      recExpenseNum: "",
      recExpenseDate: new Date(),
      expense: 0,
      expenseName: "",
      amount: 0,
      balanceAmount: 0,
      notes: "",
      processed: false,
    });
  }

  hasAdd() {
    let privilage = false;

    this.userDetails.menus.forEach((value) => {
      if (value.id === this.menuId) {
        if (value.privilegeAdd && this.recurringExpenseForm.value.id < 1)
          privilage = true;
        else if (
          value.privilegeModify &&
          this.recurringExpenseForm.value.id > 0
        )
          privilage = true;
      }
    });

    return privilage;
  }

  displayFeedback(stype: SweetAlertType, stitle: string, stext: string) {
    Swal.fire({
      toast: true,
      type: stype,
      title: stitle,
      text: stext,
      showConfirmButton: false,
      position: "top",
      timer: 3000,
    });
  }

  displayHTMLFeedback(stype: SweetAlertType, stitle: string, shtml: string) {
    Swal.fire({
      type: stype,
      title: stitle,
      html: shtml,
      showConfirmButton: true,
    });
  }
}
