import { Component, OnInit, ContentChild } from "@angular/core";
import { concat, Observable, of, Subject } from "rxjs";
import { NgbDateStruct, NgbCalendar } from "@ng-bootstrap/ng-bootstrap";
import Swal, { SweetAlertType } from "sweetalert2";
import * as XLSX from "xlsx";
import { ViewChild, ElementRef } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import {
  debounceTime,
  map,
  distinctUntilChanged,
  tap,
  switchMap,
  catchError,
} from "rxjs/operators";

import { UserDetails } from "src/app/core/models/system/user-details";
import { InvoiceRegister } from "src/app/core/models/reports/invoice-register";
import { InvoiceRegisterSearch } from "src/app/core/models/reports/invoice-register-search";
import { ReportGeneratorService } from "src/app/core/services/reports/report-generator.services";
import { FormControl, FormGroup } from "@angular/forms";

import { MonthlyProcess } from "src/app/core/models/transactions/monthly-process";
import { MonthlyProcessService } from "src/app/core/services/transactions/monthly-process.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-invoice-register",
  templateUrl: "./invoice-register.component.html",
  styleUrls: ["./invoice-register.component.css"],
})
export class InvoiceRegisterComponent implements OnInit {
  @ViewChild("table", { static: true }) table: ElementRef;

  userDetails: UserDetails;
  menuId: number;
  id: number;
  invoiceRegister: InvoiceRegister;
  listCount: any;
  processes$: Observable<MonthlyProcess[]>;
  exportHeading: string;
  exportFileName: string;

  invoiceRegisterForm = new FormGroup({
    process: new FormControl(0),
    code: new FormControl(""),
    name: new FormControl(""),
    category: new FormControl(""),
  });

  constructor(
    private calendar: NgbCalendar,
    private memberStatementService: ReportGeneratorService,
    private monthlyProcessService: MonthlyProcessService,
    private spinnerService: NgxSpinnerService,
    private route: ActivatedRoute
  ) {
    this.invoiceRegister = {
      id: 0,
      name: "",
      days: 0,
      quantity: 0,
      advanceAmount: 0,
      parcelAmount: 0,
      adjustmentAmount: 0,
      expenseAmount: 0,
      billAmount: 0,
      balanceAmount: 0,
      totalAmount: 0,
      bills: null,
    };
  }

  ngOnInit() {
    this.processes$ = this.monthlyProcessService.getMonthlyProcesss();
    this.menuId = 31000;
    this.listCount = 0;
    this.userDetails = JSON.parse(localStorage.getItem("userDetails"));
    this.processes$.subscribe((res) => console.log(res));

    this.route.paramMap.subscribe((params) => {
      const expID = +params.get("id");
      if (expID) {
        this.setInvoiceRegisterLists(expID);
      }
    });
  }

  resetFilters() {
    this.invoiceRegisterForm.reset({
      process: 0,
      code: "",
      name: "",
      category: "",
    });
    this.exportHeading = "";
    this.exportFileName = "";
  }

  setInvoiceRegisterLists(process: number) {
    this.invoiceRegisterForm.patchValue({
      process: process,
    });
    const searchFields: InvoiceRegisterSearch = {
      process: this.invoiceRegisterForm.value.process,
      code: this.invoiceRegisterForm.value.code,
      name: this.invoiceRegisterForm.value.name,
      category: this.invoiceRegisterForm.value.category,
    };
    this.spinnerService.show();

    this.memberStatementService.getInvoiceRegister(searchFields).subscribe(
      (response: InvoiceRegister) => {
        this.invoiceRegister = response;
        this.exportHeading = "Mess Bill for the Month of" + response.name;
        this.exportFileName = "Mess Bill " + response.name;
        this.listCount = this.invoiceRegister.bills.length;
        this.spinnerService.hide();
      },
      (err: any) => {
        console.log(err);
        this.spinnerService.hide();
      }
    );
    if (this.listCount === null) {
      this.listCount = 0;
    }
  }

  setInvoiceRegisterList() {
    if (this.invoiceRegisterForm.value.process === 0) {
      this.displayFeedback("warning", "Please select a month!", "");
    } else {
      const searchFields: InvoiceRegisterSearch = {
        process: this.invoiceRegisterForm.value.process,
        code: this.invoiceRegisterForm.value.code,
        name: this.invoiceRegisterForm.value.name,
        category: this.invoiceRegisterForm.value.category,
      };
      this.spinnerService.show();

      this.memberStatementService.getInvoiceRegister(searchFields).subscribe(
        (response: InvoiceRegister) => {
          this.invoiceRegister = response;
          this.exportHeading = "Mess Bill for the Month of" + response.name;
          this.exportFileName = "Mess Bill " + response.name;
          this.listCount = this.invoiceRegister.bills.length;
          this.spinnerService.hide();
        },
        (err: any) => {
          console.log(err);
          this.spinnerService.hide();
        }
      );
      if (this.listCount === null) {
        this.listCount = 0;
      }
    }
  }

  hasView() {
    let privilage = false;
    this.userDetails.menus.forEach((value) => {
      if (value.id === this.menuId) {
        if (value.privilegeView || value.privilegeModify) privilage = true;
      }
    });

    return privilage;
  }

  hasExport() {
    let privilage = false;

    this.userDetails.menus.forEach((value) => {
      if (value.id === this.menuId) {
        if (value.privilegeExport) privilage = true;
      }
    });

    return privilage;
  }

  exportToExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      this.table.nativeElement
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, this.exportFileName);

    XLSX.writeFile(wb, this.exportFileName + ".xlsx");
  }

  displayFeedback(stype: SweetAlertType, stitle: string, stext: string) {
    Swal.fire({
      toast: true,
      type: stype,
      title: stitle,
      text: stext,
      showConfirmButton: false,
      position: "top",
      timer: 3000,
    });
  }
}
