import { Component, OnInit, ContentChild } from "@angular/core";
import { Observable } from "rxjs";
import { NgbDateStruct, NgbCalendar } from "@ng-bootstrap/ng-bootstrap";
import Swal, { SweetAlertType } from "sweetalert2";
import * as XLSX from "xlsx";
import { ViewChild, ElementRef } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";

import { UserDetails } from "src/app/core/models/system/user-details";
import { ActiveMembers } from "src/app/core/models/reports/active-members";
import { ActiveMembersSearch } from "src/app/core/models/reports/active-members-search";
import { ReportGeneratorService } from "src/app/core/services/reports/report-generator.services";
import { Category } from "src/app/core/models/masters/category";
import { CategoryService } from "src/app/core/services/masters/category.service";
import { FormControl, FormGroup } from "@angular/forms";

@Component({
  selector: "app-active-members",
  templateUrl: "./active-members.component.html",
  styleUrls: ["./active-members.component.css"],
})
export class ActiveMembersComponent implements OnInit {
  @ViewChild("table", { static: true }) table: ElementRef;

  fromDate: NgbDateStruct;
  toDate: NgbDateStruct;
  categories$: Observable<Category[]>;

  userDetails: UserDetails;
  menuId: number;
  activeMembers: ActiveMembers[];
  listCount: any;

  activeMemberForm = new FormGroup({
    code: new FormControl(""),
    name: new FormControl(""),
    phone: new FormControl(""),
    email: new FormControl(""),
    category: new FormControl(0),
    hasJoinDate: new FormControl(false),
    fromDate: new FormControl(new Date()),
    toDate: new FormControl(new Date()),
  });

  constructor(
    private calendar: NgbCalendar,
    private activeMemberService: ReportGeneratorService,
    private categoryService: CategoryService,
    private spinnerService: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.fromDate = this.calendar.getToday();
    this.toDate = this.calendar.getToday();
    this.categories$ = this.categoryService.getCategorys();
    this.menuId = 31000;
    this.listCount = 0;
    this.userDetails = JSON.parse(localStorage.getItem("userDetails"));
  }

  resetFilters() {
    this.activeMemberForm.reset({
      code: "",
      name: "",
      phone: "",
      email: "",
      category: 0,
      hasJoinDate: false,
      fromDate: new Date(),
      toDate: new Date(),
    });
  }

  setActiveMemberList() {
    const searchFields: ActiveMembersSearch = {
      code: this.activeMemberForm.value.code,
      name: this.activeMemberForm.value.name,
      phone: this.activeMemberForm.value.phone,
      email: this.activeMemberForm.value.email,
      category: this.activeMemberForm.value.category,
      hasJoinDate: this.activeMemberForm.value.hasJoinDate,
      fromDate: this.activeMemberForm.value.fromDate,
      toDate: this.activeMemberForm.value.toDate,
    };
    this.spinnerService.show();

    this.activeMemberService.getActiveMembers(searchFields).subscribe(
      (response: ActiveMembers[]) => {
        this.activeMembers = response;
        this.listCount = this.activeMembers.length;
        this.spinnerService.hide();
      },
      (err: any) => {
        console.log(err);
        this.spinnerService.hide();
      }
    );
    if (this.listCount === null) {
      this.listCount = 0;
    }
  }

  hasView() {
    let privilage = false;
    this.userDetails.menus.forEach((value) => {
      if (value.id === this.menuId) {
        if (value.privilegeView || value.privilegeModify) privilage = true;
      }
    });

    return privilage;
  }

  hasExport() {
    let privilage = false;

    this.userDetails.menus.forEach((value) => {
      if (value.id === this.menuId) {
        if (value.privilegeExport) privilage = true;
      }
    });

    return privilage;
  }

  exportToExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      this.table.nativeElement
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "ActiveMembers");

    XLSX.writeFile(wb, "ActiveMembers.xlsx");
  }

  displayFeedback(stype: SweetAlertType, stitle: string, stext: string) {
    Swal.fire({
      toast: true,
      type: stype,
      title: stitle,
      text: stext,
      showConfirmButton: false,
      position: "top",
      timer: 3000,
    });
  }
}
